// Error messages
export const genericError: string =
  "We're currently experiencing technical issues. Give us some time and please try again later.";
export const passwordPolicy: string =
  "Password must 8 characters or greater and contain at least one capital letter and one number.";
export const defaultSiteDesc: string =
  "PC, PS4, Xbox compatible. The Elder Scrolls LFG (ESO LFG) engine will automatically match you with players who want to complete the same content as you. Sit back, relax and let get your group ready.";
export const defaultSiteKeywords: string =
  "eso, eso group, elder scrolls online, eso find group, eso looking group, esolfg, teso, alliance war, playstation 4, ps4, xbox, xbox one, pc, lfg, looking for group, group, party, find, dungeon, trial, pve, pvp, cyrodiil, dungeon group, trail group, can't find group";
export const defaultPageTitle: string =
  "Elder Scrolls - LFG: Find groups the easy way";

// Local storage keys
export const LS_KEY_GLOBAL_CONTEXT: string = "GLOBAL";
export const LS_KEY_ACCOUNT_CONTEXT: string = "ACCOUNT";
export const LS_KEY_CHARS_CONTEXT: string = "CHARACTERS";
export const LS_KEY_GROUP_CONTEXT: string = "GROUP";
export const LS_KEY_LFG_CONTEXT: string = "LFG";
export const LS_KEY_DATA_CONTEXT: string = "DATA";
export const LS_KEYS_ALL: string[] = [
  LS_KEY_ACCOUNT_CONTEXT,
  LS_KEY_CHARS_CONTEXT,
  LS_KEY_GROUP_CONTEXT,
  LS_KEY_LFG_CONTEXT,
  LS_KEY_DATA_CONTEXT
];

export const SESSION_EXPIRED = "sessionExpired";
export const MAX_CP = 3600;
