import GlobalContextModel from "../../domain/contexts/globalContext.model";
import {
  Account,
  Character,
  ContentType,
  GetGroupResponse,
  GetLfgResponse,
  IsInActivityResponse,
  NotFoundError
} from "@burketyler/domain";
import { env, http } from "../../App";
import { DateUnits, getFutureDate } from "@burketyler/utils";
import AccountForm from "../../mapping/ui/forms/accountForm.model";
import mapAccountForm from "../../mapping/entities/mapAccountForm.function";
import SpaGroup from "../../domain/spaGroup.model";
import { SetState } from "../../domain/setState.model";
import setLoading from "../setLoading.function";
import setPageError from "../setPageError.function";
import mapSpaGroup from "../../mapping/entities/mapSpaGroup.function";
import SpaLfg from "../../domain/spaLfg.model";
import mapSpaLfg from "../../mapping/entities/mapSpaLfg.function";
import GroupContextModel from "../../domain/contexts/groupContext.model";
import LfgContextModel from "../../domain/contexts/lfgContext.model";
import groupStreamFn from "./groupStream.function";
import isEventSourceConnected from "../isEventSoureConnected.function";
import * as H from "history";
import lfgStreamFn from "./lfgStream.function";
import AccountContextModel from "../../domain/contexts/accountContext.model";
import CharactersContextModel from "../../domain/contexts/charactersContext.model";

function getAccount(
  globalCtx: GlobalContextModel,
  acctCtx: AccountContextModel,
  setIsFirstSetup?: SetState<boolean>,
  setEditModeAccDetails?: SetState<boolean>,
  setModifiedAccount?: SetState<AccountForm>
): Promise<Account | void> {
  setLoading(globalCtx, true);
  return http
    .fetchJson<Account>(
      `${env.lambdaAccountHost}/user/${globalCtx.user.id}/account`,
      {
        method: "GET"
      },
      ContentType.JSON
    )
    .then(acctResponse => {
      acctCtx.setAccount(acctResponse);
      if (setModifiedAccount) {
        setModifiedAccount(() => mapAccountForm(acctResponse));
      }
      acctCtx.setExpires(() => getFutureDate(4, DateUnits.HOURS));
      return acctResponse;
    })
    .catch(error => {
      if (error instanceof NotFoundError) {
        if (setIsFirstSetup) {
          setIsFirstSetup(() => true);
        }
        setEditModeAccDetails(() => true);
      } else {
        setPageError(globalCtx);
      }
    })
    .finally(() => {
      setLoading(globalCtx, false);
    });
}

function getCharacterList(
  globalCtx: GlobalContextModel,
  charCtx: CharactersContextModel
): Promise<void> {
  setLoading(globalCtx, true);
  return http
    .fetchJson<Character[]>(
      `${env.lambdaCharsHost}/user/${globalCtx.user.id}/character`,
      {
        method: "GET"
      },
      ContentType.JSON
    )
    .then(characters => {
      if (charCtx.chars) {
        charCtx.setChars(() => charCtx.chars.concat(characters));
      } else {
        charCtx.setChars(() => characters);
      }
      charCtx.setExpires(() => getFutureDate(4, DateUnits.HOURS));
    })
    .finally(() => {
      setLoading(globalCtx, false);
    });
}

function getGroup(
  groupId: string,
  grpCtx: GroupContextModel,
  globalCtx: GlobalContextModel
): Promise<SpaGroup> {
  setLoading(globalCtx, true);
  return http
    .fetchJson<GetGroupResponse>(`${env.seGroupHost}/group/${groupId}`, {
      method: "GET"
    })
    .then((response: GetGroupResponse) => {
      const spaGroup: SpaGroup = mapSpaGroup(response);
      grpCtx.setIsInGroup(() => true);
      grpCtx.setGroup(prevState => {
        return {
          ...spaGroup,
          inviteCode: prevState ? prevState.inviteCode : undefined
        };
      });
      grpCtx.setExpires(globalCtx.expires || new Date());
      return spaGroup;
    })
    .finally(() => {
      setLoading(globalCtx, false);
    });
}

function getLfg(
  lfgId: string,
  globalCtx: GlobalContextModel,
  lfgCtx: LfgContextModel
): Promise<SpaLfg> {
  setLoading(globalCtx, true);
  return http
    .fetchJson<GetLfgResponse>(`${env.seGroupHost}/lfg/${lfgId}`, {
      method: "GET"
    })
    .then(response => {
      lfgCtx.setIsInLfg(() => true);
      const spaLfg: SpaLfg = mapSpaLfg(response);
      lfgCtx.setLfg(() => spaLfg);
      lfgCtx.setExpires(globalCtx.expires || new Date());
      return spaLfg;
    })
    .finally(() => {
      setLoading(globalCtx, false);
    });
}

function isInActivity(
  userId: string,
  globalCtx: GlobalContextModel,
  lfgCtx: LfgContextModel,
  groupCtx: GroupContextModel,
  history: H.History,
  token?: string
): Promise<IsInActivityResponse> {
  setLoading(globalCtx, true);
  lfgCtx.setIsContextSetup(() => false);
  groupCtx.setIsContextSetup(() => false);
  return http
    .fetchJson<IsInActivityResponse>(
      `${env.seGroupHost}/user/${userId}/activity/exists`,
      {
        method: "GET"
      }
    )
    .then(async isInActivityRes => {
      if (isInActivityRes.isInLfg) {
        if (!lfgCtx.lfg || !lfgCtx.lfg.char) {
          lfgCtx.setIsInLfg(() => true);
          await getLfg(isInActivityRes.lfgId, globalCtx, lfgCtx)
            .then(lfgRes => {
              if (!isEventSourceConnected(lfgCtx.eventSource)) {
                lfgStreamFn.connectToLfg(
                  userId,
                  lfgRes.lastEvent,
                  globalCtx,
                  lfgCtx,
                  groupCtx,
                  history,
                  token
                );
              }
            })
            .finally(() => {
              lfgCtx.setIsContextSetup(() => true);
            });
        }
      } else {
        lfgCtx.setIsInLfg(() => false);
      }
      if (isInActivityRes.isInGroup) {
        groupCtx.setIsInGroup(() => true);
        if (!groupCtx.group || !groupCtx.group.groupId) {
          await getGroup(isInActivityRes.groupId, groupCtx, globalCtx)
            .then(grpRes => {
              if (!isEventSourceConnected(groupCtx.eventSource)) {
                groupStreamFn.connectToGroup(
                  grpRes.groupId,
                  userId,
                  grpRes.lastEvent,
                  globalCtx,
                  groupCtx,
                  history,
                  token
                );
              }
            })
            .finally(() => {
              groupCtx.setIsContextSetup(() => true);
            });
        }
      } else {
        groupCtx.setIsInGroup(() => false);
      }
      return isInActivityRes;
    })
    .finally(() => {
      setLoading(globalCtx, false);
    });
}

const contextFn = {
  getCharacterList,
  getAccount,
  getGroup,
  getLfg,
  isInActivity
};

export default contextFn;
