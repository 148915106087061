import * as React from "react";
import { Col, Container, Row } from "react-grid-system";
import DivBackgroundColor from "../styled/DivBackgroundColor";
import { Palette } from "../../palette";
import {
  DivNavbarContainer,
  DivNavbarLogo,
  DivNavbarMenuIcon,
  DivNavbarNavLinks
} from "./Navbar.styled";
import { NavLinkModel } from "./Navbar.domain";
import NavLink from "./NavLink";
import NavLinkWithDropdown from "./NavLinkWithDropdown";
import { Fonts } from "../../theme";
import Ionicon from "../toolkit/ionicon/Ionicon";
import TextRingbearer from "../toolkit/text/TextRingbearer";
import { env } from "../../App";

interface Props {
  navLinks: NavLinkModel[];
  isMenuExtended: boolean;
  isMobile: boolean;
  isDesktop: boolean;
  onMobileMenuExtend: () => void;
}

const Navbar: React.FC<Props> = (props: Props) => {
  const [activeDropdown, setActiveDropdown] = React.useState(undefined);

  return (
    <DivNavbarContainer isMenuExtended={props.isMenuExtended}>
      <Container fluid>
        <DivBackgroundColor color={Palette.bgSecondary}>
          <Row>
            <Col xs={10} lg={4}>
              <DivNavbarLogo isMobile={props.isMobile}>
                <a href={"/#"}>
                  <img
                    width={props.isMobile ? 25 : 40}
                    src={`${env.contentHost}/logo-white.png`}
                    alt={"Elder Scrolls - LFG Logo"}
                    title={"Elder Scrolls LFG"}
                  />
                </a>
                <TextRingbearer
                  size={props.isMobile ? Fonts.size.regular : Fonts.size.large}
                >
                  elder scrolls lfg
                </TextRingbearer>
              </DivNavbarLogo>
            </Col>
            <Col xs={2} lg={8}>
              {!props.isDesktop && (
                <DivNavbarMenuIcon
                  isMobile={props.isMobile}
                  onClick={() => props.onMobileMenuExtend()}
                >
                  <Row>
                    <Ionicon
                      name={"menu"}
                      color={Palette.white}
                      size={props.isMobile ? "35px" : "40px"}
                    />
                  </Row>
                </DivNavbarMenuIcon>
              )}
              {props.isDesktop && (
                <DivNavbarNavLinks>
                  <Row>
                    {props.navLinks.map((link: NavLinkModel, index: number) => {
                      return link.dropDown ? (
                        <NavLinkWithDropdown
                          id={index}
                          isActive={activeDropdown === index}
                          setActiveDropdown={setActiveDropdown}
                          navLink={link}
                          key={`${link.label}-${index}`}
                        />
                      ) : (
                        <NavLink
                          navLink={link}
                          key={`${link.label}-${index}`}
                        />
                      );
                    })}
                  </Row>
                </DivNavbarNavLinks>
              )}
            </Col>
          </Row>
        </DivBackgroundColor>
      </Container>
    </DivNavbarContainer>
  );
};

export default Navbar;
