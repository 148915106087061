import * as React from "react";
import DivMargin from "../styled/DivMargin";
import DivFlexAlignJustifyCenter from "../styled/DivFlexAlignJustifyCenter";
import TextMontserrat from "../toolkit/text/TextMontserrat";
import { Fonts } from "../../theme";
import EnumInfo from "../../domain/enumInfo.model";
import getDungeonAchieveEnumInfo from "../../mapping/enums/getDungeonAchieveEnumInfo.function";
import ImgDungeonAchieveIcon from "./DungeonAchieveIcons.styled";
import { IconSelectableModel } from "../toolkit/icon-selectable/iconSelectable.model";

interface Props {
  isDesktop: boolean;
  achieves: IconSelectableModel[];
  showText: boolean;
}

const DungeonAchieveIcons: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <DivFlexAlignJustifyCenter>
      {props.achieves.map(ach => {
        const enumInfo: EnumInfo = getDungeonAchieveEnumInfo(ach.value);
        return (
          <DivMargin
            key={ach.label}
            left={props.showText ? 10 : 5}
            right={props.showText ? 10 : 5}
          >
            <DivFlexAlignJustifyCenter>
              <ImgDungeonAchieveIcon
                alt={`Group is targeting a ${enumInfo.text}.`}
                src={enumInfo.icon}
                title={enumInfo.text}
              />
            </DivFlexAlignJustifyCenter>
            {props.showText && (
              <DivMargin top={10}>
                <TextMontserrat
                  el={"div"}
                  textAlign={"center"}
                  size={props.isDesktop ? Fonts.size.small : Fonts.size.xSmall}
                >
                  {enumInfo.text}
                </TextMontserrat>
              </DivMargin>
            )}
          </DivMargin>
        );
      })}
    </DivFlexAlignJustifyCenter>
  );
};

export default DungeonAchieveIcons;
