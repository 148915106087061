import * as Sentry from "@sentry/react";
import SpaUser from "../domain/spaUser.model";

function setSentryUserContext(user: SpaUser): void {
  Sentry.configureScope(scope => {
    scope.setUser(
      user
        ? {
            id: user.id,
            gamerTag: user.gamerTag,
            email: user.username,
            groups: user.groups
          }
        : null
    );
  });
}

export default setSentryUserContext;
