import { IconSelectableModel } from "../../../components/toolkit/icon-selectable/iconSelectable.model";
import { Platform } from "@burketyler/domain";

const platformIconsArray: IconSelectableModel[] = [
  {
    icon: "laptop-outline",
    label: "Pc",
    value: Platform.PC
  },
  {
    icon: "logo-playstation",
    label: "Playstation",
    value: Platform.PLAYSTATION
  },
  {
    icon: "logo-xbox",
    label: "Xbox",
    value: Platform.XBOX
  }
];

export default platformIconsArray;
