import * as React from "react";
import { Col, Container, Row } from "react-grid-system";
import Ionicon from "../toolkit/ionicon/Ionicon";
import { Palette } from "../../palette";
import DivFlexAlignJustifyCenter from "../styled/DivFlexAlignJustifyCenter";
import DivMargin from "../styled/DivMargin";
import DivPadding from "../styled/DivPadding";
import TextMontserratSemiBold from "../toolkit/text/TextMontserratSemiBold";
import { Fonts } from "../../theme";
import Card from "../toolkit/card/Card";
import Text from "../toolkit/text/Text";
import Anchor from "../styled/Anchor";

interface Props {}

const PageError: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <Container>
      <Row>
        <Col>
          <Card fluid>
            <DivPadding padding={50}>
              <DivFlexAlignJustifyCenter>
                <Ionicon
                  name={"construct-sharp"}
                  size={"75px"}
                  color={Palette.errorBanner}
                />
              </DivFlexAlignJustifyCenter>
              <DivMargin top={30} bottom={15}>
                <TextMontserratSemiBold
                  el={"div"}
                  textAlign={"center"}
                  size={Fonts.size.medium}
                >
                  This is embarrassing
                </TextMontserratSemiBold>
              </DivMargin>
              <Text el={"div"} textAlign={"center"}>
                Something went wrong and we couldn't load this page. Please try
                again later and if the issue persists raise a ticket via
                the&nbsp;
                <Anchor href={"/#/support"}>support</Anchor>
                &nbsp;portal.
              </Text>
            </DivPadding>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PageError;
