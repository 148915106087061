import * as React from "react";
import Text from "../text/Text";
import { Fonts } from "../../../theme";

interface Props {
  children: (string | JSX.Element) | (string | JSX.Element)[];
  el?: "div" | "span";
  color?: string;
  font?: string;
  size?: string;
  textAlign?: string;
  fontWeight?: number;
  fontStyle?: string;
}

const TextMontserratMedium: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <Text
      font={Fonts.face.montserrat}
      size={props.size}
      fontWeight={Fonts.weight.medium}
      color={props.color}
      el={props.el}
      textAlign={props.textAlign}
      fontStyle={props.fontStyle}
    >
      {props.children}
    </Text>
  );
};

export default TextMontserratMedium;
