import styled from "styled-components/macro";
import Anchor from "../../styled/Anchor";
import { Fonts } from "../../../theme";

export const DivBreadCrumbsContainer = styled.div<{ isMobile: boolean }>`
  span {
    margin: 0 ${props => (props.isMobile ? 10 : 15)}px;
  }
`;

export const AnchorBreadCrumb = styled(Anchor)<{ isMobile: boolean }>`
  font-weight: ${Fonts.weight.regular};
  font-size: ${props =>
    props.isMobile ? Fonts.size.small : Fonts.weight.regular};
`;
