import styled from "styled-components/macro";
import { Palette } from "../../palette";

const DivCloseIcon = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  &:hover {
    ion-icon {
      color: ${Palette.errorBanner};
      transform: scale(1.2);
    }
  }
  ion-icon {
    transition: transform 100ms ease-in-out;
  }
`;

export default DivCloseIcon;
