export const Palette = {
  black: "#000000",
  lightBlackHover: "#1a1a1a",
  alpha: "rgba(0,0,0,0)",
  primary: "#6A2121",
  primaryHover: "#4F1919",
  secondary: "#FF8D60",
  secondaryHover: "#D97851",
  link: "#F7B500",
  linkHover: "#BF8C02",
  white: "#FFFFFF",
  lightLightGray: "#f1f1f1",
  lightGray: "#DDDDDD",
  gray: "#B2B2B2",
  darkGray: "#4E4E4F",
  darkDarkGray: "#3E3E3E",
  darkDarkGrayHover: "#363636",
  bgModal: "#2b2b2b",
  bgPrimary: "rgba(0,0,0,0.85)",
  bgFullLoader: "rgba(0,0,0,0.5)",
  bgSecondary: "rgba(0,27,47,1)",
  bgTertiary: "rgba(28,28,28,0.9)",
  ebonheart: "#C30000",
  daggerfall: "#386DF6",
  aldmeri: "#FFCC56",
  health: "#EA6969",
  stamina: "#3B8034",
  magicka: "#5C5BF5",
  sorcerer: "#336BD4",
  nightBlade: "#f5005c",
  templar: "#FFE205",
  dragonKnight: "#F65D2B",
  warden: "#018813",
  necromancer: "#7738CB",
  arcanist: "#00e721",
  success: "#0E6006",
  successLighter: "#1cb410",
  online: "#1ba00f",
  error: "#FF0000",
  errorBanner: "#AF0303",
  warning: "#FAC10A",
  warningBanner: "#e89512"
};
