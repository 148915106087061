import * as React from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Breakpoints } from "../../../theme";
import GlobalContextModel from "../../../domain/contexts/globalContext.model";
import ContextWrapperProps from "../../../domain/contextWrapperProps.model";
import NotificationBannerProps from "../../toolkit/notification-banner/notificationBanner.domain";
import SpaUser from "../../../domain/spaUser.model";
import isDefined from "../../../functions/isDefined.function";

export const GlobalContext = React.createContext<GlobalContextModel>(undefined);

interface LsType {
  isPageError: boolean;
  isPageNotFound: boolean;
  user: SpaUser;
  bearerToken: string;
  isAccountSetup: boolean;
  expires: Date;
}

interface Props extends ContextWrapperProps {
  localStorage: LsType;
}

const GlobalContextWrapper: React.FC<Props> = (props: Props): JSX.Element => {
  const [notification, setNotification] = React.useState({
    visible: false
  } as NotificationBannerProps);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isMenuExtended, setIsMenuExtended] = React.useState(false);
  const [isPageError, setIsPageError] = React.useState(
    props.localStorage ? props.localStorage.isPageError : false
  );
  const [isPageNotFound, setIsPageNotFound] = React.useState(
    props.localStorage ? props.localStorage.isPageNotFound : false
  );
  const [globalUserPrompt, setGlobalUserPrompt] = React.useState(undefined);
  const [user, setUser] = React.useState(
    props.localStorage ? props.localStorage.user : undefined
  );
  const [bearerToken, setBearerToken] = React.useState(
    props.localStorage ? props.localStorage.bearerToken : undefined
  );
  const [isAccountSetup, setIsAccountSetup] = React.useState(
    props.localStorage ? props.localStorage.isAccountSetup : false
  );
  const [expires, setExpires] = React.useState(
    props.localStorage ? props.localStorage.expires : undefined
  );

  const clearContext = (): void => {
    setUser(undefined);
    setBearerToken(undefined);
    setIsAccountSetup(undefined);
    setExpires(undefined);
  };

  useEffect(() => {
    if (
      isDefined(isPageError) ||
      isDefined(isPageNotFound) ||
      isDefined(user) ||
      isDefined(bearerToken) ||
      isDefined(isAccountSetup) ||
      isDefined(expires)
    ) {
      if (isDefined(expires)) {
        props.onChange({
          isPageError,
          isPageNotFound,
          user,
          bearerToken,
          isAccountSetup,
          expires
        });
      } else {
        props.onChange({
          isPageError,
          isPageNotFound,
          user,
          bearerToken,
          isAccountSetup
        });
      }
    }
  });

  return (
    <GlobalContext.Provider
      value={{
        notification,
        setNotification,
        isLoading,
        setIsLoading,
        isDesktop: useMediaQuery({
          minWidth: Breakpoints.desktop
        }),
        isTablet: useMediaQuery({
          minWidth: Breakpoints.tablet,
          maxWidth: Breakpoints.desktop - 1
        }),
        isMobile: useMediaQuery({
          maxWidth: Breakpoints.mobile
        }),
        isMenuExtended,
        setIsMenuExtended,
        isPageError,
        setIsPageError,
        isPageNotFound,
        setIsPageNotFound,
        globalUserPrompt,
        setGlobalUserPrompt,
        user,
        setUser,
        bearerToken,
        setBearerToken,
        isAccountSetup,
        setIsAccountSetup,
        expires,
        setExpires,
        clearContext
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextWrapper;
