import { DungeonAchieves, DungeonDetails } from "@burketyler/domain";

function mapDungeonAchievesArray(details: DungeonDetails): DungeonAchieves[] {
  const achieves: DungeonAchieves[] = [];
  if (details.speedRun) {
    achieves.push(DungeonAchieves.SPEED_RUN);
  }
  if (details.hardMode) {
    achieves.push(DungeonAchieves.HARD_MODE);
  }
  if (details.noDeath) {
    achieves.push(DungeonAchieves.NO_DEATH);
  }
  return achieves;
}

export default mapDungeonAchievesArray;
