import * as React from "react";
import { createRef } from "react";
import {
  ContainerNavbarUserLink,
  DivNavbarHighlight,
  LinkNavbarDropdown
} from "./Navbar.styled";
import { Col, Row } from "react-grid-system";
import Ionicon from "../toolkit/ionicon/Ionicon";
import NavbarDropdown from "./NavbarDropdown";
import { NavLinkModel } from "./Navbar.domain";
import TextMontserratLight from "../toolkit/text/TextMontserratLight";
import DivMargin from "../styled/DivMargin";

interface Props {
  id: number;
  navLink: NavLinkModel;
  isActive: boolean;
  setActiveDropdown: (index: number) => void;
}

const NavLinkWithDropdown: React.FC<Props> = (props: Props): JSX.Element => {
  const [elementRef] = React.useState(createRef<HTMLDivElement>());

  return (
    <React.Fragment>
      <ContainerNavbarUserLink
        onClick={() =>
          props.setActiveDropdown(props.isActive ? undefined : props.id)
        }
      >
        <div ref={elementRef}>
          <Row align={"center"}>
            <Col>
              <LinkNavbarDropdown>
                <Ionicon name={props.navLink.icon} size={"24px"} />
                <TextMontserratLight>{props.navLink.label}</TextMontserratLight>
              </LinkNavbarDropdown>
            </Col>
            <Col xs={1}>
              <LinkNavbarDropdown>
                <Ionicon
                  name={props.isActive ? "chevron-up" : "chevron-down"}
                  size={"15px"}
                />
              </LinkNavbarDropdown>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivMargin left={40} right={30}>
                <DivNavbarHighlight />
              </DivMargin>
            </Col>
          </Row>
        </div>
      </ContainerNavbarUserLink>
      <NavbarDropdown
        parent={elementRef}
        navLinks={props.navLink.dropDown}
        isVisible={props.isActive}
        setActiveDropdown={props.setActiveDropdown}
      />
    </React.Fragment>
  );
};

export default NavLinkWithDropdown;
