import styled from "styled-components/macro";
import { zIndex } from "../../theme";
import { Palette } from "../../palette";

const SkipLink = styled.button`
  position: absolute;
  top: -40px;
  left: 0;
  background-color: ${Palette.bgSecondary};
  color: ${Palette.white};
  z-index: ${zIndex.skipLink};
  &:focus {
    top: 0;
  }
`;

export default SkipLink;
