import styled from "styled-components/macro";
import { Palette } from "../../../palette";
import { zIndex } from "../../../theme";
import DivFlexAlignJustifyCenter from "../../styled/DivFlexAlignJustifyCenter";

export const DivFullPageLoadingIndicatorContainer = styled.div<{
  isVisible: boolean;
}>`
  ${props => (!props.isVisible ? "display: none" : undefined)};
  position: fixed;
  z-index: ${zIndex.fullPageLoader};
  width: 100%;
  height: 100%;
  background-color: ${Palette.bgFullLoader};
`;

export const DivFullPageLoadingIndicatorSpinnerBox = styled(
  DivFlexAlignJustifyCenter
)`
  height: 100%;
  width: 100%;
`;

export const DivFullPageLoadingIndicatorSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 111px;
    height: 111px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 85px;
    height: 85px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
