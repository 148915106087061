import { GroupMember } from "@burketyler/domain";
import SpaGroupMember from "../../domain/spaGroupMember.model";

function mapSpaGroupMember(member: GroupMember): SpaGroupMember {
  return {
    ...member,
    exited: false
  };
}

export default mapSpaGroupMember;
