import styled from "styled-components/macro";

const DivGradientBackground = styled.div`
  background-image: radial-gradient(
    circle 470px at 49.5% 48.5%,
    rgba(3, 76, 153, 0.3) 0%,
    rgba(50, 50, 50, 0.3) 95.1%
  );
`;

export default DivGradientBackground;
