import * as React from "react";
import { Palette } from "../../../palette";
import Ionicon from "../ionicon/Ionicon";
import { ButtonTheme } from "./button.domain";
import { ButtonStyled } from "./Button.styled";
import ButtonLoader from "./ButtonLoader";
import DivFlexAlignJustifyCenter from "../../styled/DivFlexAlignJustifyCenter";

interface Props {
  onClick?: () => void;
  secondary?: boolean;
  tertiary?: boolean;
  icon?: string;
  children?: string;
  width?: string;
  small?: boolean;
  large?: boolean;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
}

const Button: React.FC<Props> = (props: Props) => {
  const buildTheme = (btnProps: Props): ButtonTheme => {
    if (btnProps.secondary) {
      return {
        bgColor: Palette.secondary,
        bgColorHover: Palette.secondaryHover,
        color: Palette.white,
        border: "none",
        textDecoration: "none"
      };
    } else if (btnProps.tertiary) {
      return {
        bgColor: Palette.alpha,
        bgColorHover: Palette.primary,
        color: Palette.white,
        border: `5px solid ${Palette.primary}`,
        textDecoration: "none"
      };
    } else {
      return {
        bgColor: Palette.primary,
        bgColorHover: Palette.primaryHover,
        color: Palette.white,
        border: "none",
        textDecoration: "none"
      };
    }
  };

  const applySize = (btnProps: Props, theme: ButtonTheme): ButtonTheme => {
    if (btnProps.small) {
      theme.height = "30px";
      theme.fontSize = "12px";
    } else if (btnProps.large) {
      theme.height = "50px";
      theme.fontSize = "18px";
    } else {
      theme.height = "40px";
      theme.fontSize = "15px";
    }
    return theme;
  };

  return (
    <ButtonStyled
      onClick={props.onClick}
      width={props.width}
      theme={{ ...applySize(props, buildTheme(props)) }}
      type={props.type ? props.type : "button"}
    >
      <DivFlexAlignJustifyCenter>
        {props.icon && <Ionicon name={props.icon} size={"18px"} />}
        {props.children.toLowerCase()}
        {props.loading && <ButtonLoader />}
      </DivFlexAlignJustifyCenter>
    </ButtonStyled>
  );
};

export default Button;
