import { RadioModel } from "../../../components/toolkit/radio/radio.model";
import { LanguagePreference } from "@burketyler/domain";
import getGroupLangPrefEnumInfo from "../../enums/getGroupLangPrefEnumInfo.function";

const languagePrefRadioArray: RadioModel[] = [
  {
    label: getGroupLangPrefEnumInfo(LanguagePreference.ONLY_SAME).text,
    value: LanguagePreference.ONLY_SAME
  },
  {
    label: getGroupLangPrefEnumInfo(LanguagePreference.ANY_LANGUAGE).text,
    value: LanguagePreference.ANY_LANGUAGE
  }
];

export default languagePrefRadioArray;
