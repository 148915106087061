import * as React from "react";
import {
  DivCardHeadingRightContainer,
  DivCardContainer,
  DivCardSeparator
} from "./Card.styled";
import { Col, Container, Row } from "react-grid-system";
import { Fonts } from "../../../theme";
import DivMargin from "../../styled/DivMargin";
import TextMontserratSemiBold from "../text/TextMontserratSemiBold";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../functions/breakpoints.function";

interface Props {
  heading?: string;
  headingRight?: JSX.Element;
  fluid?: boolean;
  children?: JSX.Element | JSX.Element[];
  ref?: React.Ref<HTMLDivElement>;
  minHeight?: number;
}

const Card: React.FC<Props> = React.forwardRef(
  (props: Props, ref?: React.Ref<HTMLDivElement>): JSX.Element => {
    const isMobile = useMediaQuery(breakpoints.isMobile);

    return (
      <Container fluid={props.fluid}>
        <div ref={ref}>
          <Row>
            <Col>
              <DivCardContainer style={{ minHeight: props.minHeight }}>
                {props.heading && (
                  <Row>
                    <Col xs={8}>
                      <TextMontserratSemiBold
                        size={isMobile ? Fonts.size.regular : Fonts.size.medium}
                        el={"div"}
                      >
                        {props.heading}
                      </TextMontserratSemiBold>
                      <DivMargin top={10} bottom={30}>
                        <DivCardSeparator />
                      </DivMargin>
                    </Col>
                    {props.headingRight && (
                      <Col xs={4}>
                        <DivCardHeadingRightContainer isMobile={isMobile}>
                          {props.headingRight}
                        </DivCardHeadingRightContainer>
                      </Col>
                    )}
                  </Row>
                )}
                <Row>
                  <Col>
                    {Array.isArray(props.children)
                      ? props.children.map(child => child)
                      : props.children}
                  </Col>
                </Row>
              </DivCardContainer>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
);

export default Card;
