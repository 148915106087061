import * as React from "react";
import DivEsoBackground from "../../components/styled/DivEsoBackground";
import DivGradientBackground from "../../components/styled/DivGradientBackground";
import { Col, Row } from "react-grid-system";
import DivPageContainer from "../../components/styled/DivPageContainer";
import { Fonts } from "../../theme";
import Card from "../../components/toolkit/card/Card";
import DivPadding from "../../components/styled/DivPadding";
import DivFlexAlignJustifyCenter from "../../components/styled/DivFlexAlignJustifyCenter";
import { Palette } from "../../palette";
import DivMargin from "../../components/styled/DivMargin";
import Text from "../../components/toolkit/text/Text";
import TextMontserratSemiBold from "../../components/toolkit/text/TextMontserratSemiBold";
import Anchor from "../../components/styled/Anchor";
import Button from "../../components/toolkit/button/Button";
import { useContext } from "react";
import { GlobalContext } from "../../components/contexts/global-context-wrapper/GlobalContextWrapper";
import GlobalContextModel from "../../domain/contexts/globalContext.model";
import clearPageNotFound from "../../functions/clearPageNotFound.function";
import MainLayout from "../../layouts/MainLayout";

interface Props {}

const NotFoundPage: React.FC<Props> = (props: Props): JSX.Element => {
  const globalCtx: GlobalContextModel = useContext(GlobalContext);

  return (
    <MainLayout hideAd>
      <DivEsoBackground>
        <DivGradientBackground>
          <Row>
            <Col>
              <DivPageContainer hideAd>
                <Row justify={"center"}>
                  <Col md={7} lg={6} xl={5}>
                    <Card fluid>
                      <DivPadding padding={50}>
                        <DivFlexAlignJustifyCenter>
                          <img
                            width={150}
                            height={150}
                            src={"https://content.esolfg.com/images/404.png"}
                            alt={"Page not found"}
                            title={"Not found"}
                          />
                        </DivFlexAlignJustifyCenter>
                        <DivMargin top={30} bottom={15}>
                          <TextMontserratSemiBold
                            el={"div"}
                            textAlign={"center"}
                            size={Fonts.size.large}
                            color={Palette.secondary}
                          >
                            Page not found
                          </TextMontserratSemiBold>
                        </DivMargin>
                        <Text el={"div"} textAlign={"center"}>
                          We couldn't find the page you're trying to access. If
                          you think this is a mistake please visit the&nbsp;
                          <Anchor size={Fonts.size.regular} href={"/#/support"}>
                            support centre
                          </Anchor>
                          &nbsp;to raise a ticket.
                        </Text>
                        <DivMargin top={35}>
                          <Button
                            onClick={() => {
                              window.history.back();
                              setTimeout(() => {
                                clearPageNotFound(globalCtx);
                              }, 1000);
                            }}
                          >
                            Go back
                          </Button>
                        </DivMargin>
                      </DivPadding>
                    </Card>
                  </Col>
                </Row>
              </DivPageContainer>
            </Col>
          </Row>
        </DivGradientBackground>
      </DivEsoBackground>
    </MainLayout>
  );
};

export default NotFoundPage;
