import * as React from "react";
import { useEffect } from "react";
import { Account } from "@burketyler/domain";
import ContextWrapperProps from "../../../domain/contextWrapperProps.model";
import AccountContextModel from "../../../domain/contexts/accountContext.model";
import isDefined from "../../../functions/isDefined.function";

export const AccountContext = React.createContext<AccountContextModel>(
  undefined
);

interface Props extends ContextWrapperProps {
  localStorage: { account: Account; expires?: Date };
}

const AccountContextWrapper: React.FC<Props> = (props: Props): JSX.Element => {
  const [expires, setExpires] = React.useState(
    props.localStorage && props.localStorage.expires
      ? props.localStorage.expires
      : (undefined as Date)
  );
  const [account, setAccount] = React.useState(
    props.localStorage ? props.localStorage.account : (undefined as Account)
  );

  useEffect(() => {
    if (isDefined(account) || isDefined(expires)) {
      if (isDefined(expires)) {
        props.onChange({ account, expires });
      } else {
        props.onChange({ account });
      }
    }
  });

  const clearContext = (): void => {
    setExpires(undefined);
    setAccount(undefined);
  };

  return (
    <AccountContext.Provider
      value={{
        setAccount,
        account,
        expires,
        setExpires,
        clearContext
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export default AccountContextWrapper;
