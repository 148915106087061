import EnumInfo from "../../domain/enumInfo.model";
import { Activity } from "@burketyler/domain";

function getActivityEnumInfo(activity: Activity): EnumInfo {
  switch (activity) {
    case Activity.ARENA:
      return {
        icon: "https://content.esolfg.com/images/icons/icon-arena.png",
        text: "Arena"
      };
    case Activity.DELVE:
      return {
        icon: "https://content.esolfg.com/images/icons/icon-delve.png",
        text: "Delve"
      };
    case Activity.DOLMEN:
      return {
        icon: "https://content.esolfg.com/images/icons/icon-dolmen.png",
        text: "Dolmen"
      };
    case Activity.DUNGEON:
      return {
        icon: "https://content.esolfg.com/images/icons/icon-dungeon.png",
        text: "Dungeon"
      };
    case Activity.LEVELING:
      return {
        icon: "https://content.esolfg.com/images/icons/icon-leveling.png",
        text: "Leveling"
      };
    case Activity.PUBLIC_DUNGEON:
      return {
        icon: "https://content.esolfg.com/images/icons/icon-publicdungeon.png",
        text: "Public Dungeon"
      };
    case Activity.QUESTING:
      return {
        icon: "https://content.esolfg.com/images/icons/icon-quest.png",
        text: "Questing"
      };
    case Activity.TRIAL:
      return {
        icon: "https://content.esolfg.com/images/icons/icon-trial.png",
        text: "Trial"
      };
    case Activity.WORLD_BOSS:
      return {
        icon: "https://content.esolfg.com/images/icons/icon-worldboss.png",
        text: "World Boss"
      };
    case Activity.CYRO:
      return {
        icon: "https://content.esolfg.com/images/icons/icon-outpost.png",
        text: "Cyrodiil"
      };
    case Activity.IMPERIAL_CITY:
      return {
        icon: "https://content.esolfg.com/images/icons/icon-impcity.png",
        text: "Imp. City"
      };
  }
}

export default getActivityEnumInfo;
