import styled from "styled-components/macro";
import { Palette } from "../../palette";
import { zIndex } from "../../theme";

const DivModalBackground = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: ${Palette.bgPrimary};
  z-index: ${zIndex.modal};
`;

export default DivModalBackground;
