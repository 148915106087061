import { IconSelectableModel } from "../../../components/toolkit/icon-selectable/iconSelectable.model";
import { Microphone } from "@burketyler/domain";

const microphoneIconsArray: IconSelectableModel[] = [
  { icon: "mic", label: "I have a microphone", value: Microphone.YES },
  {
    icon: "mic-off",
    label: "I don't have a microphone",
    value: Microphone.NO
  }
];

export default microphoneIconsArray;
