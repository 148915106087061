import styled from "styled-components/macro";
import { Palette } from "../../../palette";
import { Fonts } from "../../../theme";
import * as React from "react";

const SpanText = styled.span<Props>`
  color: ${props => (props.color ? props.color : Palette.white)};
  font-family: ${props => (props.font ? props.font : Fonts.face.montserrat)};
  font-size: ${props => (props.size ? props.size : Fonts.size.regular)};
  font-weight: ${props =>
    props.fontWeight ? props.fontWeight : Fonts.weight.regular};
  ${props => (props.textAlign ? `text-align: ${props.textAlign};` : undefined)};
  ${props => (props.fontStyle ? `font-style: ${props.fontStyle};` : undefined)};
`;

const DivText = styled.div<Props>`
  color: ${props => (props.color ? props.color : Palette.white)};
  font-family: ${props => (props.font ? props.font : Fonts.face.montserrat)};
  font-size: ${props => (props.size ? props.size : Fonts.size.regular)};
  font-weight: ${props =>
    props.fontWeight ? props.fontWeight : Fonts.weight.regular};
  ${props => (props.textAlign ? `text-align: ${props.textAlign};` : undefined)};
  ${props => (props.fontStyle ? `font-style: ${props.fontStyle};` : undefined)};
`;

interface Props {
  children: (string | JSX.Element) | (string | JSX.Element)[];
  el?: "div" | "span";
  color?: string;
  font?: string;
  size?: string;
  textAlign?: string;
  fontWeight?: number;
  fontStyle?: string;
}

const Text: React.FC<Props> = (props: Props): JSX.Element => {
  return props.el === "div" ? (
    <DivText {...props}>{props.children}</DivText>
  ) : (
    <SpanText {...props}>{props.children}</SpanText>
  );
};

export default Text;
