import * as React from "react";
import { useEffect } from "react";
import { ZoneData } from "@burketyler/domain";
import ContextWrapperProps from "../../../domain/contextWrapperProps.model";
import DataContextModel from "../../../domain/contexts/dataContext.model";
import isDefined from "../../../functions/isDefined.function";

export const DataContext = React.createContext<DataContextModel>(undefined);

interface Props extends ContextWrapperProps {
  localStorage: { zoneData: ZoneData[]; expires?: Date };
}

const DataContextWrapper: React.FC<Props> = (props: Props): JSX.Element => {
  const [expires, setExpires] = React.useState(
    props.localStorage && props.localStorage.expires
      ? props.localStorage.expires
      : (undefined as Date)
  );
  const [zoneData, setZoneData] = React.useState(
    props.localStorage ? props.localStorage.zoneData : undefined
  );

  const clearContext = (): void => {
    setExpires(undefined);
    setZoneData(undefined);
  };

  useEffect(() => {
    if (isDefined(zoneData) || isDefined(expires)) {
      if (isDefined(expires)) {
        props.onChange({ zoneData, expires });
      } else {
        props.onChange({ zoneData });
      }
    }
  });

  return (
    <DataContext.Provider
      value={{
        zoneData,
        setZoneData,
        expires,
        setExpires,
        clearContext
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export default DataContextWrapper;
