import GlobalContextModel from "../domain/contexts/globalContext.model";
import { http } from "../App";
import setSentryUserContext from "./setSentryUserContext.function";

function hydrateUserContext(globalCtx: GlobalContextModel): void {
  if (globalCtx.user) {
    setSentryUserContext(globalCtx.user);
  }
  if (globalCtx.bearerToken) {
    http.setBearerToken(globalCtx.bearerToken);
  }
}

export default hydrateUserContext;
