function isLocalStorageEnabled(): boolean {
  const TEST_KEY: string = "LS_TEST";
  try {
    if (!window || !window.localStorage) {
      return false;
    } else {
      window.localStorage.setItem(TEST_KEY, "TEST");
      window.localStorage.removeItem(TEST_KEY);
      return true;
    }
  } catch (err) {
    return false;
  }
}

export default isLocalStorageEnabled;
