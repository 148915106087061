import * as React from "react";
import { Col, Container, Row } from "react-grid-system";
import DivBackgroundColor from "../styled/DivBackgroundColor";
import { Palette } from "../../palette";
import { FooterContainer } from "./Footer.styled";
import TextMontserrat from "../toolkit/text/TextMontserrat";
import { env } from "../../App";
import DivMargin from "../styled/DivMargin";

interface Props {}

const Footer: React.FC<Props> = (props: Props) => {
  return (
    <Container fluid>
      <DivBackgroundColor color={Palette.bgSecondary}>
        <Row justify={"center"}>
          <Col xs={8}>
            <FooterContainer>
              <DivMargin right={15}>
                <img
                  height={20}
                  src={`${env.contentHost}/logo-white.png`}
                  alt={"Elder Scrolls LFG"}
                  title={"Elder Scrolls LFG"}
                />
              </DivMargin>
              <TextMontserrat>Elder Scrolls LFG</TextMontserrat>
            </FooterContainer>
          </Col>
        </Row>
      </DivBackgroundColor>
    </Container>
  );
};

export default Footer;
