import * as React from "react";
import Portal from "../../toolkit/portal/Portal";
import { Col } from "react-grid-system";
import {
  DivModalContainer,
  ContainerModalContainer,
  RowModalRow
} from "./Modal.styled";
import DivModalBackground from "../../styled/DivModalBackground";

interface Props {
  isVisible: boolean;
  children: any;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
}

const Modal: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <React.Fragment>
      {props.isVisible && (
        <Portal>
          <DivModalBackground>
            <ContainerModalContainer>
              <RowModalRow justify={"center"} align={"center"}>
                <Col
                  xs={props.xs || 12}
                  sm={props.sm || 11}
                  md={props.md || 10}
                  lg={props.lg || 9}
                >
                  <DivModalContainer>{props.children}</DivModalContainer>
                </Col>
              </RowModalRow>
            </ContainerModalContainer>
          </DivModalBackground>
        </Portal>
      )}
    </React.Fragment>
  );
};

export default Modal;
