import EnumInfo from "../../domain/enumInfo.model";
import { DungeonAchieves } from "@burketyler/domain";

function getDungeonAchieveEnumInfo(options: DungeonAchieves): EnumInfo {
  switch (options) {
    case DungeonAchieves.HARD_MODE:
      return {
        icon: "https://content.esolfg.com/images/icons/icon-hardmode.png",
        text: "Hardmode"
      };
    case DungeonAchieves.NO_DEATH:
      return {
        icon: "https://content.esolfg.com/images/icons/icon-nodeath.png",
        text: "No Death"
      };
    case DungeonAchieves.SPEED_RUN:
      return {
        icon: "https://content.esolfg.com/images/icons/icon-speedrun.png",
        text: "Speed Run"
      };
  }
}

export default getDungeonAchieveEnumInfo;
