import styled from "styled-components/macro";
import { Palette } from "../../../palette";
import { Container, Row } from "react-grid-system";

export const ContainerModalContainer = styled(Container)`
  height: 100%;
`;

export const RowModalRow = styled(Row)`
  height: 100%;
`;

export const DivModalContainer = styled.div<{ padding?: number }>`
  background-color: ${Palette.bgModal};
  padding: ${props => props.padding || 35}px;
`;
