import styled from "styled-components/macro";

const H1NoStyle = styled.h1`
  display: inherit;
  font-size: inherit;
  margin-block-start: inherit;
  margin-block-end: inherit;
  margin-inline-start: inherit;
  margin-inline-end: inherit;
  font-weight: inherit;
`;

export default H1NoStyle;
