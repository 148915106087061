import * as React from "react";
import TextMontserrat from "../text/TextMontserrat";
import { Fonts } from "../../../theme";
import { UserPromptProps } from "./user-prompt.model";
import { Col, Container, Row } from "react-grid-system";
import Ionicon from "../ionicon/Ionicon";
import DivMargin from "../../styled/DivMargin";
import Button from "../button/Button";
import TextMontserratMedium from "../text/TextMontserratMedium";
import { Palette } from "../../../palette";
import DivCloseIcon from "../../styled/DivCloseIcon";
import {
  DivUserPromptBgContainer,
  DivUserPromptContainer
} from "./UserPrompt.styled";
import Portal from "../portal/Portal";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../functions/breakpoints.function";

const UserPrompt: React.FC<UserPromptProps> = (
  props: UserPromptProps
): JSX.Element => {
  const isMobile: boolean = useMediaQuery(breakpoints.isMobile);

  return (
    <React.Fragment>
      {props.visible && (
        <Portal>
          <DivUserPromptBgContainer>
            <Container fluid style={{ height: "100%" }}>
              <Row
                justify={"center"}
                align={"center"}
                style={{ height: "100%" }}
              >
                <Col lg={8} xl={6}>
                  <DivUserPromptContainer isDesktop={props.isDesktop}>
                    <Row justify={props.isDesktop ? "end" : "start"}>
                      <Col xs={11}>
                        <TextMontserratMedium
                          size={Fonts.size.medium}
                          color={Palette.secondary}
                        >
                          {props.title}
                        </TextMontserratMedium>
                      </Col>
                      {props.closeIcon && (
                        <Col xs={1}>
                          <DivCloseIcon
                            onClick={() => props.onButtonClicked("close")}
                          >
                            <Ionicon
                              size={"25px"}
                              name={"close-circle-outline"}
                            />
                          </DivCloseIcon>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col>
                        <DivMargin top={50} bottom={isMobile ? 50 : 75}>
                          <TextMontserrat
                            el={"div"}
                            textAlign={isMobile ? "left" : "center"}
                            size={Fonts.size.medium}
                          >
                            {props.text}
                          </TextMontserrat>
                        </DivMargin>
                      </Col>
                    </Row>
                    {props.buttons && props.buttons.length > 0 && (
                      <Row justify={"center"} align={"center"}>
                        <Col
                          lg={props.buttons.length * 4 + 2}
                          xl={props.buttons.length * 4}
                        >
                          <Row>
                            {props.buttons.map((btn, index) => (
                              <Col
                                xs={props.isDesktop ? undefined : 12}
                                key={btn.text}
                              >
                                <DivMargin
                                  right={
                                    index === props.buttons.length - 1
                                      ? 0
                                      : props.isDesktop
                                      ? 15
                                      : 0
                                  }
                                  bottom={
                                    index === props.buttons.length - 1
                                      ? 0
                                      : !props.isDesktop
                                      ? 15
                                      : 0
                                  }
                                >
                                  <Button
                                    large={!props.isDesktop}
                                    secondary={btn.variant === "secondary"}
                                    tertiary={btn.variant === "tertiary"}
                                    onClick={() =>
                                      props.onButtonClicked(
                                        btn.text.toLowerCase()
                                      )
                                    }
                                  >
                                    {btn.text}
                                  </Button>
                                </DivMargin>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </DivUserPromptContainer>
                </Col>
              </Row>
            </Container>
          </DivUserPromptBgContainer>
        </Portal>
      )}
    </React.Fragment>
  );
};

export default UserPrompt;
