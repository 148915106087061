import * as React from "react";
import {
  DivFullPageLoadingIndicatorContainer,
  DivFullPageLoadingIndicatorSpinnerBox
} from "./FullPageLoadingIndicator.styled";
import FullPageSpinner from "./FullPageSpinner";
import { Fonts } from "../../../theme";
import DivMargin from "../../styled/DivMargin";
import TextMontserratSemiBold from "../text/TextMontserratSemiBold";
import Portal from "../portal/Portal";

interface Props {
  isVisible: boolean;
}

const FullPageLoadingIndicator: React.FC<Props> = (
  props: Props
): JSX.Element => {
  return (
    <Portal>
      <DivFullPageLoadingIndicatorContainer isVisible={props.isVisible}>
        <DivFullPageLoadingIndicatorSpinnerBox>
          <div>
            <FullPageSpinner />
            <DivMargin top={25}>
              <TextMontserratSemiBold el={"div"} size={Fonts.size.medium}>
                Please wait...
              </TextMontserratSemiBold>
            </DivMargin>
          </div>
        </DivFullPageLoadingIndicatorSpinnerBox>
      </DivFullPageLoadingIndicatorContainer>
    </Portal>
  );
};

export default FullPageLoadingIndicator;
