import isDefined from "./isDefined.function";

function isAllObjectValuesEmpty(...objects: any[]): boolean {
  if (isDefined(objects) && objects.length > 0) {
    return objects.reduce((isEmpty: boolean, obj: any) => {
      if (obj) {
        return (
          Object.values(obj).filter(field => isDefined(field) && field !== "")
            .length === 0
        );
      } else {
        return true;
      }
    }, true);
  } else {
    return false;
  }
}

export default isAllObjectValuesEmpty;
