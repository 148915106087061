import EnumInfo from "../../domain/enumInfo.model";
import { Difficulty } from "@burketyler/domain";

function getDifficultyEnumInfo(difficulty: Difficulty): EnumInfo {
  switch (difficulty) {
    case Difficulty.NORMAL:
      return { text: "Normal" };
    case Difficulty.VETERAN:
      return { text: "Veteran" };
  }
}

export default getDifficultyEnumInfo;
