import GlobalContextModel from "../domain/contexts/globalContext.model";
import isDefined from "./isDefined.function";

function isActivated(context: GlobalContextModel): boolean {
  return (
    isDefined(context) && isDefined(context.user) && context.user.isActivated
  );
}

export default isActivated;
