import styled from "styled-components/macro";
import { Palette } from "../../../palette";
import { Animations } from "../../../theme";

export const DivIoniconContainer = styled.span<{
  size?: string;
  color?: string;
  spinner?: boolean;
}>`
  line-height: 0;
  ion-icon {
    color: ${props => (props.color ? props.color : Palette.white)};
    font-size: ${props => (props.size ? props.size : "16px")};
    ${props => (props.spinner ? Animations.spinner : undefined)}
  }
`;
