import styled from "styled-components/macro";
import { Palette } from "../../../palette";
import { zIndex } from "../../../theme";

export const DivUserPromptBgContainer = styled.div`
  position: fixed;
  background-color: ${Palette.bgFullLoader};
  z-index: ${zIndex.modal};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const DivUserPromptContainer = styled.div<{ isDesktop: boolean }>`
  background-color: ${Palette.bgModal};
  min-height: 300px;
  padding: ${props => (props.isDesktop ? 40 : 25)}px;
`;
