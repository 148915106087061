import * as React from "react";
import { useEffect } from "react";
import ContextWrapperProps from "../../../domain/contextWrapperProps.model";
import GroupContextModel from "../../../domain/contexts/groupContext.model";
import SpaGroupEvent from "../../../domain/spaGroupEvent.model";
import SpaGroup from "../../../domain/spaGroup.model";
import { SetState } from "../../../domain/setState.model";
import EventSource from "eventsource";
import isDefined from "../../../functions/isDefined.function";

export const GroupContext = React.createContext<GroupContextModel>(undefined);

interface LsType {
  group: SpaGroup;
  isInGroup: boolean;
  events: SpaGroupEvent[];
  expires: Date;
}

interface Props extends ContextWrapperProps {
  onChange: SetState<LsType>;
  localStorage: LsType;
}

const GroupContextWrapper: React.FC<Props> = (props: Props): JSX.Element => {
  const [group, setGroup] = React.useState(
    props.localStorage ? props.localStorage.group : undefined
  );
  const [isInGroup, setIsInGroup] = React.useState(
    props.localStorage ? props.localStorage.isInGroup : false
  );
  const [events, setEvents] = React.useState(
    props.localStorage ? props.localStorage.events : ([] as SpaGroupEvent[])
  );
  const [eventSource, setEventSource] = React.useState(
    undefined as EventSource
  );
  const [isContextSetup, setIsContextSetup] = React.useState(false);
  const [expires, setExpires] = React.useState(
    props.localStorage ? props.localStorage.expires : undefined
  );
  const [errorTimeout, setErrorTimeout] = React.useState<number>();

  const clearContext = () => {
    setIsInGroup(false);
    setGroup(undefined);
    setEvents([]);
    setEventSource(undefined);
    setErrorTimeout(undefined);
    setExpires(undefined);
  };

  useEffect(() => {
    if (
      isDefined(group) ||
      isDefined(events) ||
      isDefined(isInGroup) ||
      isDefined(expires)
    ) {
      props.onChange({
        group,
        events,
        isInGroup,
        expires
      });
    }
  });

  return (
    <GroupContext.Provider
      value={{
        clearContext,
        group,
        setGroup,
        isInGroup,
        setIsInGroup,
        events,
        setEvents,
        eventSource,
        setEventSource,
        isContextSetup,
        setIsContextSetup,
        errorTimeout,
        setErrorTimeout,
        expires,
        setExpires
      }}
    >
      {props.children}
    </GroupContext.Provider>
  );
};

export default GroupContextWrapper;
