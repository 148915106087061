import * as React from "react";
import {
  DivNotificationBannerClose,
  DivNotificationBannerContainer,
  DivNotificationBannerSection
} from "./NotificationBanner.styled";
import { Col, Container, Row } from "react-grid-system";
import Ionicon from "../ionicon/Ionicon";
import { Palette } from "../../../palette";
import Text from "../text/Text";
import { Fonts } from "../../../theme";
import NotificationBannerProps from "./notificationBanner.domain";
import DivPadding from "../../styled/DivPadding";
import TextMontserratSemiBold from "../text/TextMontserratSemiBold";
import Portal from "../portal/Portal";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../functions/breakpoints.function";

const NotificationBanner: React.FC<NotificationBannerProps> = (
  props: NotificationBannerProps
): JSX.Element => {
  const isMobile: boolean = useMediaQuery(breakpoints.isMobile);

  const getVariantColor = () => {
    switch (props.variant) {
      case "error":
        return Palette.errorBanner;
      case "warning":
        return Palette.warningBanner;
      case "success":
        return Palette.success;
    }
  };

  const getVariantIcon = () => {
    switch (props.variant) {
      case "success":
        return "checkmark-circle-outline";
      case "warning":
        return "alert-circle-outline";
      case "error":
        return "alert-circle-outline";
    }
  };

  return (
    <React.Fragment>
      {props.visible && (
        <Portal>
          <DivNotificationBannerContainer bgColor={getVariantColor()}>
            <Container fluid>
              <Row justify={"center"} align={"center"}>
                <Col lg={9} xl={8}>
                  <Row align={"center"}>
                    <Col xs={2} md={1}>
                      <DivNotificationBannerSection
                        justify={"center"}
                        isMobile={isMobile}
                      >
                        <Ionicon
                          name={getVariantIcon()}
                          color={Palette.white}
                          size={"35px"}
                        />
                      </DivNotificationBannerSection>
                    </Col>
                    <Col xs={8} md={9} lg={10}>
                      <DivNotificationBannerSection
                        justify={"flex-start"}
                        isMobile={isMobile}
                      >
                        <TextMontserratSemiBold
                          size={
                            isMobile ? Fonts.size.small : Fonts.size.regular
                          }
                        >
                          {props.message}
                        </TextMontserratSemiBold>
                      </DivNotificationBannerSection>
                    </Col>
                    <Col xs={2} md={2} lg={1}>
                      <DivNotificationBannerClose
                        justify={"center"}
                        onClick={props.onClose}
                        isMobile={isMobile}
                      >
                        <Ionicon
                          name={"close-circle"}
                          color={Palette.white}
                          size={"20px"}
                        />
                        {!isMobile && (
                          <DivPadding left={10}>
                            <Text size={Fonts.size.small}>Close</Text>
                          </DivPadding>
                        )}
                      </DivNotificationBannerClose>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </DivNotificationBannerContainer>
        </Portal>
      )}
    </React.Fragment>
  );
};

export default NotificationBanner;
