import GlobalContextModel from "../../domain/contexts/globalContext.model";
import {
  ConnectionStatus,
  ContentType,
  EventData,
  GetGroupResponse,
  InviteSentData,
  LfgEventType
} from "@burketyler/domain";
import * as H from "history";
import SpaLfg from "../../domain/spaLfg.model";
import setGlobalUserPrompt from "../setGlobalUserPrompt.function";
import LfgContextModel from "../../domain/contexts/lfgContext.model";
import EventSource from "eventsource";
import safeCloseEventSource from "../safeCloseEventSource.function";
import { env, http } from "../../App";
import setNotification from "../setNotification.function";
import GroupContextModel from "../../domain/contexts/groupContext.model";
import mapSpaGroup from "../../mapping/entities/mapSpaGroup.function";
import groupStreamFn from "./groupStream.function";
import { SetState } from "../../domain/setState.model";

function connectToLfg(
  userId: string,
  lastEvent: number,
  globalCtx: GlobalContextModel,
  lfgCtx: LfgContextModel,
  groupCtx: GroupContextModel,
  history: H.History,
  token?: string
): void {
  console.log("Connecting to lfg");
  const eventSource = new EventSource(
    `${env.seGroupHost}/lfg/user/${userId}/stream`,
    {
      headers: {
        Authorization: `Bearer ${token || globalCtx.bearerToken}`,
        "Last-Event-ID": lastEvent.toString()
      }
    }
  );
  if (lfgCtx.errorTimeout) {
    clearTimeout(lfgCtx.errorTimeout);
  }
  addEventListeners(eventSource, lfgCtx, groupCtx, globalCtx, history);
  lfgCtx.setEventSource(eventSource);
}

function addEventListeners(
  eventSource: EventSource,
  lfgCtx: LfgContextModel,
  groupCtx: GroupContextModel,
  globalCtx: GlobalContextModel,
  history: H.History
): void {
  eventSource.onerror = (event: any): void => {
    handleError.bind(eventSource)(
      lfgCtx.clearContext,
      globalCtx,
      lfgCtx.setErrorTimeout,
      event
    );
  };
  eventSource.addEventListener(
    `${LfgEventType.QUEUE_STATUS}`,
    (event: any): void => {
      handleChangeQueueStatus(lfgCtx.setLfg, event);
    }
  );
  eventSource.addEventListener(
    `${LfgEventType.CONN_STATUS}`,
    (event: any): void => {
      handleChangeConnStatus(lfgCtx.setLfg, event);
    }
  );
  eventSource.addEventListener(`${LfgEventType.END}`, (event: any): void => {
    handleEnd(lfgCtx.clearContext, history);
  });
  eventSource.addEventListener(`${LfgEventType.KICK}`, (event: any): void => {
    handleKicked(lfgCtx.clearContext, globalCtx, history);
  });
  eventSource.addEventListener(`${LfgEventType.INVITE}`, (event: any): void => {
    handleInvite(lfgCtx.setLfg, lfgCtx.setInvite, event);
  });
  eventSource.addEventListener(`${LfgEventType.JOIN}`, (event: any): void => {
    handleJoin(lfgCtx.clearContext, groupCtx, globalCtx, history, event);
  });
}

function handleChangeQueueStatus(setLfg: SetState<SpaLfg>, event: any): void {
  console.log("QState");
  const statusEvent: EventData = JSON.parse(event.data);
  setLfg(
    (prevState: SpaLfg): SpaLfg => {
      return {
        ...prevState,
        queueStatus: statusEvent.data,
        lastEvent: Number(event.lastEventId)
      };
    }
  );
}

function handleChangeConnStatus(setLfg: SetState<SpaLfg>, event: any): void {
  console.log("ConStatus");
  const statusEvent: EventData = JSON.parse(event.data);
  setLfg(
    (prevState: SpaLfg): SpaLfg => {
      return {
        ...prevState,
        connStatus: statusEvent.data,
        lastEvent: Number(event.lastEventId)
      };
    }
  );
}

function handleEnd(clearContext: SetState<void>, history: H.History): void {
  console.log("End");
  clearContext();
  if (history.location.pathname.split("/")[1] === "lfg") {
    history.push("/");
  }
}

function handleKicked(
  clearContext: SetState<void>,
  globalCtx: GlobalContextModel,
  history: H.History
): void {
  console.log("Kicked");
  setGlobalUserPrompt(globalCtx, {
    visible: true,
    title: "Inactive",
    text: "You have been removed from the queue due to inactivity.",
    buttons: [{ text: "Ok", variant: "primary" }],
    onButtonClicked: () => {
      clearContext();
      if (history.location.pathname.split("/")[1] === "lfg") {
        history.push("/");
      }
      setGlobalUserPrompt(globalCtx, undefined);
    }
  });
}

function handleInvite(
  setLfg: SetState<SpaLfg>,
  setInvite: SetState<InviteSentData>,
  event: any
): void {
  console.log("Invite");
  const inviteEvent: EventData = JSON.parse(event.data);
  const inviteData: InviteSentData = inviteEvent.data;
  setLfg(prevState => {
    return { ...prevState, lastEvent: Number(event.lastEventId) };
  });
  setInvite(() => inviteData);
}

function handleJoin(
  clearContext: SetState<void>,
  groupCtx: GroupContextModel,
  globalCtx: GlobalContextModel,
  history: H.History,
  event: any
): void {
  console.log("Join");
  const joinEvent: EventData = JSON.parse(event.data);
  const getGroupRes: GetGroupResponse = joinEvent.data;
  clearContext();
  groupCtx.setGroup(() => mapSpaGroup(getGroupRes));
  groupCtx.setIsInGroup(() => true);
  groupStreamFn.connectToGroup(
    getGroupRes.group.groupId,
    globalCtx.user.id,
    getGroupRes.lastEvent,
    globalCtx,
    groupCtx,
    history
  );
  history.push(`/group/${getGroupRes.group.groupId}`);
}

function handleError(
  this: EventSource,
  clearContext: SetState<void>,
  globalCtx: GlobalContextModel,
  setErrorTimeout: SetState<number>,
  event: any
): void {
  console.log("Error", event);
  const typedEvent: { status: number; message: string } = event;
  safeCloseEventSource(this);
  if (typedEvent.message === undefined) {
    // The source closed the connection
    console.log("Server disconnected stream");
  } else if (typedEvent.status === 404 || !globalCtx.user) {
    clearContext();
  } else if (typedEvent.message === "network error") {
    setErrorTimeout(
      setTimeout(() => {
        setNotification(
          globalCtx,
          "warning",
          "You aren't connected to the LFG stream. Please refresh the page to reconnect."
        );
      }, 5000)
    );
  } else {
    setNotification(
      globalCtx,
      "warning",
      "You aren't connected to the LFG stream. Please refresh the page to reconnect."
    );
  }
}

async function changeConnectionStatus(
  status: ConnectionStatus,
  userId: string
): Promise<void> {
  await http.fetch(
    `${env.seGroupHost}/lfg/${userId}/stream/status`,
    {
      method: "PUT",
      body: JSON.stringify(status)
    },
    ContentType.JSON
  );
}

const lfgStreamFn = {
  connectToLfg,
  changeConnectionStatus
};

export default lfgStreamFn;
