import { Breakpoints } from "../theme";

const isDesktop = {
  minWidth: Breakpoints.desktop
};

const isTablet = {
  minWidth: Breakpoints.tablet,
  maxWidth: Breakpoints.desktop - 1
};

const isMobile = {
  maxWidth: Breakpoints.mobile
};

export const breakpoints = {
  isDesktop,
  isTablet,
  isMobile
};
