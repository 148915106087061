import GroupContextModel from "../domain/contexts/groupContext.model";
import isDefined from "./isDefined.function";

function isInGroup(groupCtx: GroupContextModel): boolean {
  return (
    isDefined(groupCtx) &&
    isDefined(groupCtx.isInGroup) &&
    isDefined(groupCtx.group) &&
    isDefined(groupCtx.group.groupId)
  );
}

export default isInGroup;
