import * as React from "react";
import { env } from "../../App";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

interface Props {
  children: any;
}

const RecaptchaWrapper: React.FC<Props> = (props: Props): JSX.Element => {
  return env.captchaEnabled ? (
    <GoogleReCaptchaProvider reCaptchaKey={env.captchaKey}>
      {props.children}
    </GoogleReCaptchaProvider>
  ) : (
    props.children
  );
};

export default RecaptchaWrapper;
