import { IconSelectableModel } from "../../../components/toolkit/icon-selectable/iconSelectable.model";
import getDungeonAchieveEnumInfo from "../../enums/getDungeonAchieveEnumInfo.function";
import { DungeonAchieves } from "@burketyler/domain";

const dungeonAchievesIconsArray: IconSelectableModel[] = [
  {
    src: getDungeonAchieveEnumInfo(DungeonAchieves.SPEED_RUN).icon,
    label: getDungeonAchieveEnumInfo(DungeonAchieves.SPEED_RUN).text,
    value: DungeonAchieves.SPEED_RUN
  },
  {
    src: getDungeonAchieveEnumInfo(DungeonAchieves.HARD_MODE).icon,
    label: getDungeonAchieveEnumInfo(DungeonAchieves.HARD_MODE).text,
    value: DungeonAchieves.HARD_MODE
  },
  {
    src: getDungeonAchieveEnumInfo(DungeonAchieves.NO_DEATH).icon,
    label: getDungeonAchieveEnumInfo(DungeonAchieves.NO_DEATH).text,
    value: DungeonAchieves.NO_DEATH
  }
];

export default dungeonAchievesIconsArray;
