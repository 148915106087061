import GlobalContextModel from "../domain/contexts/globalContext.model";
import { UserPromptProps } from "../components/toolkit/user-prompt/user-prompt.model";

function setGlobalUserPrompt(
  globalCtx: GlobalContextModel,
  prompt: Omit<UserPromptProps, "isDesktop">
): void {
  globalCtx.setGlobalUserPrompt(prompt as UserPromptProps);
}

export default setGlobalUserPrompt;
