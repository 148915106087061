import * as React from "react";
import { useContext } from "react";
import { Redirect } from "react-router-dom";
import LfgContextModel from "../../domain/contexts/lfgContext.model";
import { LfgContext } from "../contexts/lfg-context-wrapper/LfgContextWrapper";
import isInLfg from "../../functions/isInLfg.function";

interface Props {
  children: any;
}

const CantBeInLfgGuard: React.FC<Props> = (props: Props): JSX.Element => {
  const lfgCtx: LfgContextModel = useContext(LfgContext);

  return isInLfg(lfgCtx) ? (
    <Redirect to={`/lfg/${lfgCtx.lfg.char.userId}`} />
  ) : (
    props.children
  );
};

export default CantBeInLfgGuard;
