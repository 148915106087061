import * as React from "react";
import DivEsoBackground from "../../components/styled/DivEsoBackground";
import DivGradientBackground from "../../components/styled/DivGradientBackground";
import { Col, Row } from "react-grid-system";
import { Fonts } from "../../theme";
import Card from "../../components/toolkit/card/Card";
import DivPadding from "../../components/styled/DivPadding";
import DivFlexAlignJustifyCenter from "../../components/styled/DivFlexAlignJustifyCenter";
import { Palette } from "../../palette";
import DivMargin from "../../components/styled/DivMargin";
import Text from "../../components/toolkit/text/Text";
import TextMontserratSemiBold from "../../components/toolkit/text/TextMontserratSemiBold";
import AnchorButton from "../../components/toolkit/anchor-button/AnchorButton";
import { useHistory } from "react-router-dom";
import GlobalContextModel from "../../domain/contexts/globalContext.model";
import { useContext } from "react";
import { GlobalContext } from "../../components/contexts/global-context-wrapper/GlobalContextWrapper";

interface Props {}

const InternalErrorPage: React.FC<Props> = (props: Props): JSX.Element => {
  const history = useHistory();
  const globalCtx: GlobalContextModel = useContext(GlobalContext);

  return (
    <DivEsoBackground>
      <DivGradientBackground>
        <Row style={{ height: "100%" }}>
          <Col style={{ height: "100%" }}>
            <Row justify={"center"} align={"center"} style={{ height: "100%" }}>
              <Col md={7} lg={6} xl={5}>
                <Card fluid>
                  <DivPadding padding={50}>
                    <DivFlexAlignJustifyCenter>
                      <img
                        width={150}
                        height={150}
                        src={"https://content.esolfg.com/images/error.png"}
                        alt={"Internal error"}
                        title={"Error"}
                      />
                    </DivFlexAlignJustifyCenter>
                    <DivMargin top={30} bottom={15}>
                      <TextMontserratSemiBold
                        el={"div"}
                        textAlign={"center"}
                        size={Fonts.size.large}
                        color={Palette.secondary}
                      >
                        Uh oh!
                      </TextMontserratSemiBold>
                    </DivMargin>
                    <Text el={"div"} textAlign={"center"}>
                      Something went terribly wrong and we couldn't recover.
                      This error has been reported to the support team. To
                      continue using the Elder Scrolls LFG app please&nbsp;
                      <AnchorButton
                        size={Fonts.size.regular}
                        onClick={() => {
                          history.push(
                            `${window.location.protocol}//${window.location.host}/#/`
                          );
                          globalCtx.setIsPageError(false);
                        }}
                      >
                        click here
                      </AnchorButton>
                      &nbsp;to restart from the home page.
                    </Text>
                  </DivPadding>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </DivGradientBackground>
    </DivEsoBackground>
  );
};

export default InternalErrorPage;
