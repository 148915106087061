import * as React from "react";
import { DivInlineFlexCenter } from "../styled/DivInlineFlexCenter";
import DivMargin from "../styled/DivMargin";
import DivTextAlign from "../styled/DivTextAlign";
import { Fonts } from "../../theme";
import { Palette } from "../../palette";
import DivFlexAlignJustifyCenter from "../styled/DivFlexAlignJustifyCenter";
import TextMontserratSemiBold from "../toolkit/text/TextMontserratSemiBold";
import TextRingbearer from "../toolkit/text/TextRingbearer";
import TextMontserrat from "../toolkit/text/TextMontserrat";
import { DivInviteInfoContainer } from "./DivInviteInfo.styled";

interface Props {
  iconSrc: string;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  activity?: string;
  activityName?: string;
  activityPrimaryOption?: string;
  activityOptions?: JSX.Element;
}

const InviteInfo: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <DivInviteInfoContainer isDesktop={props.isDesktop}>
      {!props.isDesktop && (
        <DivFlexAlignJustifyCenter>
          <DivMargin bottom={20}>
            <img
              src={props.iconSrc}
              alt={props.activity}
              title={props.activity}
            />
          </DivMargin>
        </DivFlexAlignJustifyCenter>
      )}
      <DivFlexAlignJustifyCenter>
        <DivInlineFlexCenter>
          {props.isDesktop && (
            <DivMargin right={20}>
              <img
                src={props.iconSrc}
                alt={props.activity}
                title={props.activity}
              />
            </DivMargin>
          )}
          <DivTextAlign align={"center"}>
            {props.activity && (
              <TextMontserratSemiBold
                el={"div"}
                size={props.isMobile ? Fonts.size.medium : Fonts.size.large}
              >
                {props.activity}
              </TextMontserratSemiBold>
            )}
            {props.activityName && (
              <DivMargin top={5} bottom={5}>
                <TextRingbearer
                  el={"div"}
                  color={Palette.secondary}
                  size={props.isMobile ? Fonts.size.medium : Fonts.size.xLarge}
                >
                  {props.activityName}
                </TextRingbearer>
              </DivMargin>
            )}
            {props.activityPrimaryOption && (
              <TextMontserrat el={"div"}>
                {props.activityPrimaryOption}
              </TextMontserrat>
            )}
            <DivMargin top={20}>
              {props.activityOptions && props.activityOptions}
            </DivMargin>
          </DivTextAlign>
        </DivInlineFlexCenter>
      </DivFlexAlignJustifyCenter>
    </DivInviteInfoContainer>
  );
};

export default InviteInfo;
