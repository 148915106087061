import { GetGroupResponse } from "@burketyler/domain";
import SpaGroup from "../../domain/spaGroup.model";
import mapSpaGroupMember from "./mapSpaGroupMember.function";

function mapSpaGroup(response: GetGroupResponse): SpaGroup {
  return {
    ...response.group,
    lastEvent: response.lastEvent,
    inviteCode: undefined,
    members: response.group.members.map(gm => mapSpaGroupMember(gm))
  };
}

export default mapSpaGroup;
