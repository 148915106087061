import * as React from "react";
import { useContext } from "react";
import {
  DivNavmenuContainer,
  DivNavmenuLink,
  DivNavmenuUnderline,
  DivNavmenuUser,
  DivNavmenuUserName
} from "./Navmenu.styled";
import { Col, Container, Row } from "react-grid-system";
import { NavLinkModel } from "../navbar/Navbar.domain";
import { Palette } from "../../palette";
import { Fonts, footerHeight, navbarHeight } from "../../theme";
import Ionicon from "../toolkit/ionicon/Ionicon";
import AnchorNoStyle from "../styled/AnchorNoStyle";
import TextMontserratSemiBold from "../toolkit/text/TextMontserratSemiBold";
import TextRingbearer from "../toolkit/text/TextRingbearer";
import { GlobalContext } from "../contexts/global-context-wrapper/GlobalContextWrapper";
import GlobalContextModel from "../../domain/contexts/globalContext.model";

interface Props {
  navbarElement: React.RefObject<HTMLDivElement>;
  navLinks: NavLinkModel[];
}

const Navmenu: React.FC<Props> = (props: Props): JSX.Element => {
  const globalCtx: GlobalContextModel = useContext(GlobalContext);
  const windowHeight: number = Math.trunc(window.innerHeight);

  return (
    <DivNavmenuContainer height={windowHeight - navbarHeight - footerHeight}>
      <Container fluid>
        {globalCtx && globalCtx.user && globalCtx.user.gamerTag && (
          <Row>
            <Col>
              <DivNavmenuUser>
                <Ionicon
                  name={"person-circle"}
                  color={Palette.white}
                  size={"30px"}
                />
                <DivNavmenuUserName>
                  <TextMontserratSemiBold size={Fonts.size.regular}>
                    {globalCtx.user.gamerTag}
                  </TextMontserratSemiBold>
                  <DivNavmenuUnderline />
                </DivNavmenuUserName>
              </DivNavmenuUser>
            </Col>
          </Row>
        )}
        {props.navLinks.map((link: NavLinkModel, index: number) => {
          return (
            <AnchorNoStyle
              href={link.href}
              key={`${link.label}-${index}`}
              onClick={() => globalCtx.setIsMenuExtended(false)}
            >
              <Row>
                <Col>
                  <DivNavmenuLink>
                    <Ionicon
                      name={link.icon}
                      color={Palette.white}
                      size={"20px"}
                    />
                    <TextRingbearer size={Fonts.size.regular}>
                      {link.label.toLowerCase()}
                    </TextRingbearer>
                  </DivNavmenuLink>
                </Col>
              </Row>
            </AnchorNoStyle>
          );
        })}
      </Container>
    </DivNavmenuContainer>
  );
};

export default Navmenu;
