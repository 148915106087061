import * as React from "react";
import { useContext } from "react";
import onFirstRender from "../../functions/onFirstRender.function";
import DataContextModel from "../../domain/contexts/dataContext.model";
import { DataContext } from "../contexts/data-context-wrapper/DataContextWrapper";
import dataFn from "./data.function";
import GlobalContextModel from "../../domain/contexts/globalContext.model";
import { GlobalContext } from "../contexts/global-context-wrapper/GlobalContextWrapper";
import setNotification from "../../functions/setNotification.function";
import { DateUnits, getFutureDate } from "@burketyler/utils";

interface Props {
  children: any;
}

const DataInitializer: React.FC<Props> = (props: Props): JSX.Element => {
  const globalCtx: GlobalContextModel = useContext(GlobalContext);
  const dataCtx: DataContextModel = useContext(DataContext);

  onFirstRender(() => {
    if (dataCtx.zoneData === undefined) {
      dataFn.getZoneData(globalCtx, dataCtx).catch(err => {
        setNotification(
          globalCtx,
          "error",
          "Loading global zone data failed, you may see incorrect zone information in dropdowns and lists."
        );
      });
    }
    dataCtx.setExpires(getFutureDate(2, DateUnits.DAYS));
  });

  return props.children;
};

export default DataInitializer;
