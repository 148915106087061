import GlobalContextModel from "../domain/contexts/globalContext.model";

function setNotification(
  globalCtx: GlobalContextModel,
  variant: "error" | "success" | "warning",
  message: string
): void {
  globalCtx.setNotification({
    visible: true,
    variant,
    message
  });
}

export default setNotification;
