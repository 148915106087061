import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { useContext } from "react";
import { GlobalContext } from "../contexts/global-context-wrapper/GlobalContextWrapper";
import { RouteProps } from "react-router";
import GlobalContextModel from "../../domain/contexts/globalContext.model";
import isLoggedIn from "../../functions/isLoggedIn.function";
import isActivated from "../../functions/isActivated.function";

interface Props {
  children?: any;
}

const SecureRoute: React.FC<Props & RouteProps> = (
  props: Props
): JSX.Element => {
  const globalCtx: GlobalContextModel = useContext(GlobalContext);

  const runGuard = (): JSX.Element => {
    if (!isLoggedIn(globalCtx)) {
      return <Redirect to={`/login?returnUrl=${window.location.href}`} />;
    } else if (!isActivated(globalCtx)) {
      return <Redirect to={"/user/activate"} />;
    } else {
      return props.children;
    }
  };

  return <Route {...props}>{runGuard()}</Route>;
};

export default SecureRoute;
