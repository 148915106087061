import styled from "styled-components/macro";

const AnchorNoStyle = styled.a`
  text-decoration-skip-ink: inherit;
  color: inherit;
  cursor: inherit;
  text-decoration: inherit;
`;

export default AnchorNoStyle;
