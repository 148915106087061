import styled from "styled-components/macro";
import { Palette } from "../../palette";
import { Spacing } from "../../theme";

export const DivNavmenuContainer = styled.div<{ height: number }>`
  height: ${props => props.height}px;
  width: 100%;
  background-color: ${Palette.bgSecondary};
`;

export const DivNavmenuLink = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  user-select: none;
  margin: 0 ${Spacing.four};
  padding: ${Spacing.two} 0;
  ion-icon {
    margin-right: ${Spacing.three};
  }
`;

export const DivNavmenuUnderline = styled.div`
  background: ${Palette.secondary};
  width: 100%;
  height: 2px;
`;

export const DivNavmenuUserName = styled.div`
  align-self: center;
  margin-left: ${Spacing.two};
`;

export const DivNavmenuUser = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${Palette.bgPrimary};
  padding: ${Spacing.four} 0;
  margin-bottom: ${Spacing.two};
`;
