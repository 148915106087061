export const navbarHeight: number = 75;
export const advertisementHeight: number = 150;
export const footerHeight: number = 60;
export const groupMemberCardHeight: number = 142;

export const Breakpoints = {
  desktop: 992,
  tablet: 768,
  mobile: 576
};

export const Animations = {
  spinner: `animation: spin 2s linear infinite;
            @keyframes spin {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }`,
  spinnerSlow: `animation: spin 7s linear infinite;
            @keyframes spin {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }`
};

export const Fonts = {
  face: {
    ringBearer: "RingBearer, Helvetica, Arial, sans-serif",
    montserrat: "Montserrat, Helvetica, Arial, sans-serif"
  },
  size: {
    xSmall: "10px",
    small: "12px",
    regular: "15px",
    medium: "20px",
    large: "25px",
    xLarge: "35px"
  },
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600
  }
};

export const Transitions = {
  navLink: "background-color 250ms ease-in-out",
  button: "background-color 150ms ease-in-out"
};

export const zIndex = {
  foreground: "0",
  dropDown: "1",
  control: "2",
  notification: "3",
  fullPageLoader: "4",
  fixed: "5",
  modal: "6",
  skipLink: "100"
};

export const Spacing = {
  one: "5px",
  two: "10px",
  three: "15px",
  four: "25px",
  five: "40px",
  six: "60px"
};
