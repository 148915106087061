import * as React from "react";
import { NavLinkModel } from "./Navbar.domain";
import { Col, Container, Row } from "react-grid-system";
import {
  ContainerNavbarLink,
  DivNavbarHighlight,
  DivNavbarLink,
  SpanNavbarSpacer
} from "./Navbar.styled";
import Ionicon from "../toolkit/ionicon/Ionicon";
import AnchorNoStyle from "../styled/AnchorNoStyle";
import TextMontserratLight from "../toolkit/text/TextMontserratLight";
import DivMargin from "../styled/DivMargin";

interface Props {
  navLink: NavLinkModel;
}

const NavLink: React.FC<Props> = (props: Props): JSX.Element => {
  return props.navLink.label === "|" ? (
    <Container>
      <Row>
        <Col>
          <SpanNavbarSpacer />
        </Col>
      </Row>
    </Container>
  ) : (
    <ContainerNavbarLink>
      <AnchorNoStyle href={props.navLink.href}>
        <Row align={"center"} justify={"start"}>
          <Col>
            <DivNavbarLink>
              <Ionicon name={props.navLink.icon} size={"24px"} />
              <TextMontserratLight>{props.navLink.label}</TextMontserratLight>
            </DivNavbarLink>
          </Col>
        </Row>
        <Row>
          <Col>
            <DivMargin left={40}>
              <DivNavbarHighlight />
            </DivMargin>
          </Col>
        </Row>
      </AnchorNoStyle>
    </ContainerNavbarLink>
  );
};

export default NavLink;
