import AccountForm from "../ui/forms/accountForm.model";
import { Account } from "@burketyler/domain";
import platformIconsArray from "../ui/icon-sets/platformIconsArray.model";
import microphoneIconsArray from "../ui/icon-sets/microphoneIconsArray.model";
import serverRadioArray from "../ui/radios/serverRadioArray.model";
import languagePrefRadioArray from "../ui/radios/languagePrefRadioArray.model";

function mapAccountForm(account: Account): AccountForm {
  return {
    platform: platformIconsArray.find(pl => pl.value === account.platform),
    server: serverRadioArray.find(sr => sr.value === account.server),
    microphone: microphoneIconsArray.find(
      mic => mic.value === account.microphone
    ),
    language: { label: account.language, value: account.language },
    languagePref: languagePrefRadioArray.find(
      lp => lp.value === account.languagePref
    )
  };
}

export default mapAccountForm;
