import styled from "styled-components/macro";

const DivMinHeight = styled.div<{ height: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${props => props.height}px;
`;

export default DivMinHeight;
