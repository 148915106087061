import * as React from "react";
import Modal from "../toolkit/modal/Modal";
import { Col, Row } from "react-grid-system";
import DivMargin from "../styled/DivMargin";
import Button from "../toolkit/button/Button";
import { InviteSentData } from "@burketyler/domain";
import InviteInfoAdapter from "../invite-info/InviteInfoAdapter";
import { Palette } from "../../palette";
import TextMontserratMedium from "../toolkit/text/TextMontserratMedium";
import { Fonts } from "../../theme";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { DivInviteModalContainer } from "./InviteModal.styled";
import TextMontserratLight from "../toolkit/text/TextMontserratLight";
import { useContext } from "react";
import { LfgContext } from "../contexts/lfg-context-wrapper/LfgContextWrapper";
import isAllObjectValuesEmpty from "../../functions/isAllObjectValuesEmpty";
import isDefined from "../../functions/isDefined.function";

interface Props {
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isVisible: boolean;
  onAccept: () => void;
  onDecline: () => void;
  invite: InviteSentData;
}

const InviteModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { setInvite } = useContext(LfgContext);

  const calculateDuration = (): number => {
    let duration: number;
    if (props.invite && !isAllObjectValuesEmpty(props.invite)) {
      duration =
        (new Date(props.invite.requiredMember.invite.expiry).getTime() -
          new Date().getTime()) /
        1000;
    }
    return isDefined(duration) && duration > 0 ? duration : 0;
  };

  const renderTime = (remainingTime: number): JSX.Element => {
    const fontSize: string = "15px";
    return (
      <TextMontserratLight
        el={"div"}
        size={fontSize}
        textAlign={"center"}
      >{`${remainingTime}`}</TextMontserratLight>
    );
  };

  return (
    <Modal isVisible={props.isVisible}>
      <Row>
        <Col>
          <Row justify={"start"} align={"center"}>
            <Col>
              <DivMargin bottom={30}>
                <TextMontserratMedium
                  size={Fonts.size.medium}
                  color={Palette.secondary}
                >
                  Group ready
                </TextMontserratMedium>
              </DivMargin>
            </Col>
            <Col>
              <DivInviteModalContainer>
                <CountdownCircleTimer
                  isPlaying
                  duration={calculateDuration()}
                  colors={[
                    [Palette.link, 0.8],
                    [Palette.secondary, 0.2],
                    [Palette.error, 0]
                  ]}
                  size={75}
                  strokeWidth={8}
                  onComplete={() => {
                    setInvite(() => {
                      return {} as InviteSentData;
                    });
                  }}
                >
                  {({ remainingTime }) => renderTime(remainingTime)}
                </CountdownCircleTimer>
              </DivInviteModalContainer>
            </Col>
          </Row>
          <Row>
            <Col>
              <DivMargin bottom={50}>
                <InviteInfoAdapter
                  invite={props.invite}
                  isDesktop={props.isDesktop}
                  isTablet={props.isTablet}
                  isMobile={props.isMobile}
                />
              </DivMargin>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <DivMargin
                right={props.isDesktop || props.isTablet ? 10 : 0}
                bottom={props.isDesktop || props.isTablet ? 0 : 10}
              >
                <Button onClick={props.onAccept}>Join</Button>
              </DivMargin>
            </Col>
            <Col md={6}>
              <DivMargin left={props.isDesktop || props.isTablet ? 10 : 0}>
                <Button tertiary onClick={props.onDecline}>
                  Decline
                </Button>
              </DivMargin>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default InviteModal;
