import * as React from "react";
import { Col, Container, Row } from "react-grid-system";
import { DivCardPageContainer } from "./Card.styled";
import { breakpoints } from "../../../functions/breakpoints.function";
import { useMediaQuery } from "react-responsive";
import calcPageHeight from "../../../functions/calcPageHeight.function";
import { Breadcrumb } from "../breadcrumbs/breadcrumbs.model";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import DivPadding from "../../styled/DivPadding";

interface Props {
  breadcrumbs: Breadcrumb[];
  heading: string;
  children: any;
  hideUi?: boolean;
  hideAd?: boolean;
}

const CardPage: React.FC<Props> = (props: Props): JSX.Element => {
  const windowHeight: number = Math.trunc(window.innerHeight);
  const isMobile = useMediaQuery(breakpoints.isMobile);

  return (
    <Container fluid={isMobile}>
      <Row>
        <Col>
          <DivCardPageContainer
            height={calcPageHeight(windowHeight, props.hideUi) - 40}
          >
            <Row>
              <Col>
                <Breadcrumbs
                  currentPage={props.heading}
                  breadcrumbs={props.breadcrumbs}
                />
              </Col>
            </Row>
            <Row align={"center"} justify={"center"} style={{ flex: 1 }}>
              <Col>
                <DivPadding bottom={25}>
                  {Array.isArray(props.children)
                    ? props.children.map(child => child)
                    : props.children}
                </DivPadding>
              </Col>
            </Row>
          </DivCardPageContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default CardPage;
