import styled from "styled-components/macro";
import { zIndex } from "../../../theme";

export const DivNotificationBannerContainer = styled.div<{
  bgColor: string;
}>`
  top: 0;
  position: fixed;
  z-index: ${zIndex.notification};
  width: 100%;
  background-color: ${props => props.bgColor};
`;

export const DivNotificationBannerSection = styled.div<{
  justify: string;
  isMobile: boolean;
}>`
  min-height: ${props => (props.isMobile ? 100 : 75)}px;
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify};
`;

export const DivNotificationBannerClose = styled(DivNotificationBannerSection)`
  cursor: pointer;
  user-select: none;
`;
