import * as React from "react";
import { useEffect } from "react";
import ContextWrapperProps from "../../../domain/contextWrapperProps.model";
import LfgContextModel from "../../../domain/contexts/lfgContext.model";
import { Group, InviteSentData } from "@burketyler/domain";
import SpaLfg from "../../../domain/spaLfg.model";
import EventSource from "eventsource";
import isDefined from "../../../functions/isDefined.function";

export const LfgContext = React.createContext<LfgContextModel>(undefined);

interface LsType {
  lfg: SpaLfg;
  invite: InviteSentData;
  isInLfg: boolean;
  similarGroups: Group[];
  declineCount: number;
  expires: Date;
}

interface Props extends ContextWrapperProps {
  onChange: (ls: LsType) => void;
  localStorage: LsType;
}

const LfgContextWrapper: React.FC<Props> = (props: Props): JSX.Element => {
  const [lfg, setLfg] = React.useState(
    props.localStorage ? props.localStorage.lfg : undefined
  );
  const [invite, setInvite] = React.useState(
    props.localStorage ? props.localStorage.invite : undefined
  );
  const [eventSource, setEventSource] = React.useState(
    undefined as EventSource
  );
  const [isInLfg, setIsInLfg] = React.useState(
    props.localStorage ? props.localStorage.isInLfg : false
  );
  const [similarGroups, setSimilarGroups] = React.useState(
    props.localStorage ? props.localStorage.similarGroups : undefined
  );
  const [declineCount, setDeclineCount] = React.useState(
    props.localStorage ? props.localStorage.declineCount : 0
  );
  const [isContextSetup, setIsContextSetup] = React.useState(false);
  const [errorTimeout, setErrorTimeout] = React.useState<number>();
  const [expires, setExpires] = React.useState(
    props.localStorage ? props.localStorage.expires : undefined
  );

  const clearContext = () => {
    setDeclineCount(0);
    setIsInLfg(false);
    setLfg(undefined);
    setInvite(undefined);
    setSimilarGroups(undefined);
    setEventSource(undefined);
    setErrorTimeout(undefined);
    setExpires(undefined);
  };

  useEffect(() => {
    if (
      isDefined(lfg) ||
      isDefined(invite) ||
      isDefined(similarGroups) ||
      isDefined(declineCount) ||
      isDefined(expires)
    ) {
      props.onChange({
        lfg,
        invite,
        isInLfg,
        similarGroups,
        declineCount,
        expires
      });
    }
  });

  return (
    <LfgContext.Provider
      value={{
        clearContext,
        lfg,
        setLfg,
        invite,
        setInvite,
        eventSource,
        setEventSource,
        isInLfg,
        setIsInLfg,
        similarGroups,
        setSimilarGroups,
        declineCount,
        setDeclineCount,
        isContextSetup,
        setIsContextSetup,
        errorTimeout,
        setErrorTimeout,
        expires,
        setExpires
      }}
    >
      {props.children}
    </LfgContext.Provider>
  );
};

export default LfgContextWrapper;
