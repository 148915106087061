import * as React from "react";
import DivMinHeight from "./DivMinHeight";
import DivPadding from "./DivPadding";
import calcPageHeight from "../../functions/calcPageHeight.function";

interface Props {
  children?: JSX.Element | JSX.Element[];
  hideAd?: boolean;
  hideUi?: boolean;
}

const DivPageContainer: React.FC<Props> = (props: Props): JSX.Element => {
  const windowHeight: number = Math.trunc(window.innerHeight);
  return (
    <DivMinHeight height={calcPageHeight(windowHeight, props.hideUi)}>
      <DivPadding top={20} bottom={20} style={{ width: "100%" }}>
        {Array.isArray(props.children)
          ? props.children.map(child => child)
          : props.children}
      </DivPadding>
    </DivMinHeight>
  );
};

export default DivPageContainer;
