import LfgContextModel from "../domain/contexts/lfgContext.model";
import isDefined from "./isDefined.function";

function isInLfg(lfgCtx: LfgContextModel): boolean {
  return (
    isDefined(lfgCtx) &&
    isDefined(lfgCtx.isInLfg) &&
    isDefined(lfgCtx.lfg) &&
    isDefined(lfgCtx.lfg.char)
  );
}

export default isInLfg;
