import styled from "styled-components/macro";
import { Fonts, Spacing, Transitions } from "../../../theme";
import { ButtonTheme } from "./button.domain";

export const ButtonStyled = styled.button<{
  theme: ButtonTheme;
  width: string;
}>`
  cursor: pointer;
  background-color: ${props => props.theme.bgColor};
  color: ${props => props.theme.color};
  font-family: ${Fonts.face.ringBearer};
  font-size: ${props => props.theme.fontSize};
  width: ${props => (props.width ? props.width : "100%")};
  height: ${props => props.theme.height};
  border: ${props => props.theme.border};
  transition: ${Transitions.button};
  &:hover,
  focus {
    background-color: ${props => props.theme.bgColorHover};
    text-decoration: ${props => props.theme.textDecoration};
  }
  &:active {
    background-color: ${props => props.theme.bgColorHover};
    text-decoration: ${props => props.theme.textDecoration};
  }
  ion-icon {
    margin-right: ${Spacing.one};
  }
`;

export const DivButtonLoader = styled.span`
  margin-left: 10px;
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 23px;
    height: 23px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 50%;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 5px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 5px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 19px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 34px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(12px, 0);
    }
  }
`;
