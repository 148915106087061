import * as React from "react";
import { useContext, useRef } from "react";
import { Col, Container, Row } from "react-grid-system";
import DivEsoBackground from "../components/styled/DivEsoBackground";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import DivGradientBackground from "../components/styled/DivGradientBackground";
import DivPageContainer from "../components/styled/DivPageContainer";
import NotificationBanner from "../components/toolkit/notification-banner/NotificationBanner";
import FullPageLoadingIndicator from "../components/toolkit/full-page-loading-indicator/FullPageLoadingIndicator";
import { GlobalContext } from "../components/contexts/global-context-wrapper/GlobalContextWrapper";
import UserPrompt from "../components/toolkit/user-prompt/UserPrompt";
import InviteModal from "../components/invite-modal/InviteModal";
import lfgFn from "../pages/lfg/lfg.function";
import setNotification from "../functions/setNotification.function";
import {
  defaultPageTitle,
  defaultSiteDesc,
  defaultSiteKeywords,
  genericError
} from "../constants";
import { LfgContext } from "../components/contexts/lfg-context-wrapper/LfgContextWrapper";
import isAllObjectValuesEmpty from "../functions/isAllObjectValuesEmpty";
import LfgContextModel from "../domain/contexts/lfgContext.model";
import GroupContextModel from "../domain/contexts/groupContext.model";
import { GroupContext } from "../components/contexts/group-context-wrapper/GroupContextWrapper";
import PageError from "../components/page-error/PageError";
import NotFoundPage from "../pages/not-found/NotFoundPage";
import createNavlinks from "../functions/createNavlinks.function";
import setGlobalUserPrompt from "../functions/setGlobalUserPrompt.function";
import GlobalContextModel from "../domain/contexts/globalContext.model";
import Navmenu from "../components/navmenu/Navmenu";
import createMenulinks from "../functions/createNavmenu.function";
import SkipLink from "../components/styled/SkipLink";
import scrollRefIntoView from "../functions/scrollRefIntoView.function";
import { Helmet } from "react-helmet";
import isInGroup from "../functions/isInGroup.function";
import { useIdleTimer } from "react-idle-timer";
import isLoggedIn from "../functions/isLoggedIn.function";
import groupStreamFn from "../functions/contexts/groupStream.function";
import { ConnectionStatus } from "@burketyler/domain";
import { env } from "../App";
import isInLfg from "../functions/isInLfg.function";
import lfgStreamFn from "../functions/contexts/lfgStream.function";

interface Props {
  children: any;
  hideAd?: boolean;
}

const MainLayout: React.FC<Props> = (props: Props) => {
  const lfgCtx: LfgContextModel = useContext(LfgContext);
  const groupCtx: GroupContextModel = useContext(GroupContext);
  const globalCtx: GlobalContextModel = useContext(GlobalContext);
  const navbarElement = useRef<HTMLDivElement>();
  const refMain = useRef<HTMLDivElement>();

  const handleIdle = async (): Promise<void> => {
    if (isLoggedIn(globalCtx)) {
      if (isInGroup(groupCtx)) {
        await groupStreamFn.changeConnectionStatus(
          ConnectionStatus.IDLE,
          groupCtx.group.groupId,
          globalCtx.user.id
        );
      }
      if (isInLfg(lfgCtx)) {
        await lfgStreamFn.changeConnectionStatus(
          ConnectionStatus.IDLE,
          globalCtx.user.id
        );
      }
    }
  };

  const handleActive = async (): Promise<void> => {
    if (isLoggedIn(globalCtx)) {
      if (isInGroup(groupCtx)) {
        await groupStreamFn.changeConnectionStatus(
          ConnectionStatus.ONLINE,
          groupCtx.group.groupId,
          globalCtx.user.id
        );
      }
      if (isInLfg(lfgCtx)) {
        await lfgStreamFn.changeConnectionStatus(
          ConnectionStatus.ONLINE,
          globalCtx.user.id
        );
      }
    }
  };

  useIdleTimer({
    timeout: env.idleTimeMs,
    onIdle: handleIdle,
    onActive: handleActive
  });

  const renderBody = (): JSX.Element => {
    if (globalCtx.isMenuExtended) {
      return (
        <Navmenu
          navbarElement={navbarElement}
          navLinks={createMenulinks(globalCtx, groupCtx, lfgCtx)}
        />
      );
    } else if (globalCtx.isPageError) {
      return <PageError />;
    } else {
      return (
        <DivPageContainer hideAd={props.hideAd}>
          {props.children}
        </DivPageContainer>
      );
    }
  };

  return (
    <React.Fragment>
      <Helmet
        titleTemplate={`%s | ${defaultPageTitle}`}
        defaultTitle={defaultPageTitle}
      >
        <meta name="description" content={defaultSiteDesc} />
        <meta name="keywords" content={defaultSiteKeywords} />
      </Helmet>
      {globalCtx.isPageNotFound ? (
        <NotFoundPage />
      ) : (
        <React.Fragment>
          <FullPageLoadingIndicator isVisible={globalCtx.isLoading} />
          <NotificationBanner
            onClose={() => globalCtx.setNotification({ visible: false })}
            {...globalCtx.notification}
          />
          <UserPrompt
            isDesktop={globalCtx.isDesktop}
            {...globalCtx.globalUserPrompt}
          />
          <InviteModal
            isDesktop={globalCtx.isDesktop}
            isTablet={globalCtx.isTablet}
            isMobile={globalCtx.isMobile}
            isVisible={!isAllObjectValuesEmpty(lfgCtx.invite)}
            onAccept={() => {
              lfgFn.acceptInvite(globalCtx, lfgCtx).catch(() => {
                setNotification(globalCtx, "error", genericError);
              });
            }}
            onDecline={() => {
              lfgFn
                .declineInvite(globalCtx, lfgCtx)
                .then(() => {
                  lfgCtx.setDeclineCount(prevState => {
                    if (prevState + 1 > 4) {
                      return 0;
                    } else {
                      return prevState + 1;
                    }
                  });
                  if (lfgCtx.declineCount + 1 === 4) {
                    setGlobalUserPrompt(globalCtx, {
                      visible: true,
                      title: "Decline limit reached",
                      text:
                        "You've declined too many group invitations. If you decline another, you will be removed from the LFG queue.",
                      buttons: [{ variant: "primary", text: "Ok" }],
                      onButtonClicked: () => {
                        setGlobalUserPrompt(globalCtx, undefined);
                      }
                    });
                  }
                })
                .catch(() => {
                  setNotification(globalCtx, "error", genericError);
                });
            }}
            invite={lfgCtx.invite}
          />
          <DivEsoBackground>
            <DivGradientBackground>
              <Container fluid>
                <Row>
                  <Col>
                    <div ref={navbarElement}>
                      <nav>
                        <SkipLink
                          role={"link"}
                          tabIndex={0}
                          onClick={() => scrollRefIntoView(refMain)}
                        >
                          Skip to main
                        </SkipLink>
                        <Navbar
                          navLinks={createNavlinks(globalCtx, groupCtx, lfgCtx)}
                          isMenuExtended={globalCtx.isMenuExtended}
                          isMobile={globalCtx.isMobile}
                          isDesktop={globalCtx.isDesktop}
                          onMobileMenuExtend={() => {
                            globalCtx.setIsMenuExtended(prevState => {
                              return !prevState;
                            });
                          }}
                        />
                      </nav>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <main ref={refMain}>{renderBody()}</main>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Footer />
                  </Col>
                </Row>
              </Container>
            </DivGradientBackground>
          </DivEsoBackground>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default MainLayout;
