import {
  ConnectionStatus,
  ContentType,
  Group,
  LfgStatus
} from "@burketyler/domain";
import GlobalContextModel from "../../domain/contexts/globalContext.model";
import { Response } from "node-fetch";
import { env, http } from "../../App";
import LfgContextModel from "../../domain/contexts/lfgContext.model";
import setLoading from "../../functions/setLoading.function";
import { SetState } from "../../domain/setState.model";

function getSimilarGroups(
  globalCtx: GlobalContextModel,
  lfgCtx: LfgContextModel,
  setIsRelatedGroupsLoaded: SetState<boolean>
): Promise<void> {
  setLoading(globalCtx, true);
  return http
    .fetchJson<Group[]>(
      `${env.seGroupHost}/lfg/${globalCtx.user.id}/related-groups`,
      {
        method: "GET"
      }
    )
    .then(groups => {
      lfgCtx.setSimilarGroups(groups);
      setIsRelatedGroupsLoaded(true);
    })
    .finally(() => {
      setLoading(globalCtx, false);
    });
}

function acceptInvite(
  globalCtx: GlobalContextModel,
  lfgCtx: LfgContextModel
): Promise<Response> {
  setLoading(globalCtx, true);
  return http
    .fetch(
      `${env.seGroupHost}/group/${lfgCtx.invite.groupId}/invite/${lfgCtx.invite.requiredMember.invite.inviteId}/accept`,
      { method: "POST" }
    )
    .finally(() => {
      lfgCtx.setIsInLfg(false);
      lfgCtx.setLfg(undefined);
      lfgCtx.setInvite(undefined);
      setLoading(globalCtx, false);
    });
}

function joinGroup(
  groupId: string,
  globalCtx: GlobalContextModel
): Promise<Response> {
  setLoading(globalCtx, true);
  return http
    .fetch(`${env.seGroupHost}/group/${groupId}/join`, { method: "POST" })
    .finally(() => {
      setLoading(globalCtx, false);
    });
}

function declineInvite(
  globalCtx: GlobalContextModel,
  lfgCtx: LfgContextModel
): Promise<Response> {
  setLoading(globalCtx, true);
  return http
    .fetch(
      `${env.seGroupHost}/group/${lfgCtx.invite.groupId}/invite/${lfgCtx.invite.requiredMember.invite.inviteId}`,
      { method: "DELETE" }
    )
    .finally(() => {
      lfgCtx.setInvite(undefined);
      setLoading(globalCtx, false);
    });
}

function changeQueuedStatus(
  status: LfgStatus,
  globalCtx: GlobalContextModel
): Promise<Response> {
  setLoading(globalCtx, true);
  return http
    .fetch(
      `${env.seGroupHost}/lfg/${globalCtx.user.id}/status`,
      {
        method: "PUT",
        body: JSON.stringify(status)
      },
      ContentType.JSON
    )
    .finally(() => {
      setLoading(globalCtx, false);
    });
}

function changeConnectionStatus(
  status: ConnectionStatus,
  globalCtx: GlobalContextModel
): Promise<Response> {
  setLoading(globalCtx, true);
  return http
    .fetch(
      `${env.seGroupHost}/lfg/${globalCtx.user.id}/stream/status`,
      {
        method: "PUT",
        body: JSON.stringify(status)
      },
      ContentType.JSON
    )
    .finally(() => {
      setLoading(globalCtx, false);
    });
}

function leaveQueue(globalCtx: GlobalContextModel): Promise<Response> {
  setLoading(globalCtx, true);
  return http
    .fetch(`${env.seGroupHost}/lfg/${globalCtx.user.id}`, {
      method: "DELETE"
    })
    .finally(() => {
      setLoading(globalCtx, false);
    });
}

const lfgFn = {
  acceptInvite,
  declineInvite,
  changeConnectionStatus,
  changeQueuedStatus,
  leaveQueue,
  getSimilarGroups,
  joinGroup
};

export default lfgFn;
