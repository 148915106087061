import * as React from "react";
import { DivFullPageLoadingIndicatorSpinner } from "./FullPageLoadingIndicator.styled";

interface Props {}

const FullPageSpinner: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <DivFullPageLoadingIndicatorSpinner>
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </DivFullPageLoadingIndicatorSpinner>
  );
};

export default FullPageSpinner;
