import * as React from "react";
import { useContext } from "react";
import { Redirect } from "react-router-dom";
import GroupContextModel from "../../domain/contexts/groupContext.model";
import { GroupContext } from "../contexts/group-context-wrapper/GroupContextWrapper";
import isInGroup from "../../functions/isInGroup.function";

interface Props {
  children: any;
}

const CantBeInGroupGuard: React.FC<Props> = (props: Props): JSX.Element => {
  const groupCtx: GroupContextModel = useContext(GroupContext);

  return isInGroup(groupCtx) ? (
    <Redirect to={`/group/${groupCtx.group.groupId}`} />
  ) : (
    props.children
  );
};

export default CantBeInGroupGuard;
