import { SESSION_EXPIRED } from "../constants";
import {
  ApiError,
  BadRequestError,
  ServiceUnavailableError,
  UnauthorizedError
} from "@burketyler/domain";
import * as Sentry from "@sentry/react";

function interceptHttpError(error: ApiError): void {
  if (
    error instanceof ServiceUnavailableError ||
    error instanceof BadRequestError
  ) {
    Sentry.captureException(error);
  }
  if (error instanceof UnauthorizedError) {
    window.location.href = `/#/logout?reason=${SESSION_EXPIRED}`;
  }
}

export default interceptHttpError;
