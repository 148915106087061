import * as React from "react";
import {
  Activity,
  ArenaDetails,
  DungeonAchieves,
  DungeonDetails,
  InviteSentData,
  OpenWorldPvpDetails,
  ZonedDetails
} from "@burketyler/domain";
import EnumInfo from "../../domain/enumInfo.model";
import getDifficultyEnumInfo from "../../mapping/enums/getDifficultyEnumInfo.function";
import InviteInfo from "./InviteInfo";
import getActivityEnumInfo from "../../mapping/enums/getActivityEnumInfo.function";
import DungeonAchieveIcons from "../dungeon-achieve-icons/DungeonAchieveIcons";
import dungeonAchievesIconsArray from "../../mapping/ui/icon-sets/dungeonAchievesArray.model";
import mapDungeonAchievesArray from "../../mapping/entities/mapDungeonAchievesArray.function";

interface Props {
  invite: InviteSentData;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
}

const InviteInfoAdapter: React.FC<Props> = (props: Props): JSX.Element => {
  const renderDungeonInviteInfo = (activityInfo: EnumInfo) => {
    const details = props.invite.details as DungeonDetails;
    const achieves: DungeonAchieves[] = mapDungeonAchievesArray(details);
    return (
      <InviteInfo
        iconSrc={activityInfo.icon}
        activity={activityInfo.text}
        activityName={details.type || "any"}
        activityPrimaryOption={`${
          getDifficultyEnumInfo(details.difficulty).text
        } Difficulty`}
        activityOptions={
          <DungeonAchieveIcons
            isDesktop={props.isDesktop}
            showText={props.isDesktop}
            achieves={
              achieves
                ? achieves.map(opt =>
                    dungeonAchievesIconsArray.find(ic => ic.value === opt)
                  )
                : []
            }
          />
        }
        isDesktop={props.isDesktop}
        isTablet={props.isTablet}
        isMobile={props.isMobile}
      />
    );
  };

  const renderZonedInviteInfo = (activityInfo: EnumInfo) => {
    const details = props.invite.details as ZonedDetails;
    return (
      <InviteInfo
        iconSrc={activityInfo.icon}
        activity={activityInfo.text}
        activityName={details.type || "any"}
        activityPrimaryOption={
          details.type ? undefined : details.zone || "Any Zone"
        }
        isDesktop={props.isDesktop}
        isTablet={props.isTablet}
        isMobile={props.isMobile}
      />
    );
  };

  const renderArenaInviteInfo = (activityInfo: EnumInfo) => {
    const details = props.invite.details as ArenaDetails;
    return (
      <InviteInfo
        iconSrc={activityInfo.icon}
        activity={activityInfo.text}
        activityName={details.type}
        activityPrimaryOption={`${
          getDifficultyEnumInfo(details.difficulty).text
        } Difficulty`}
        isDesktop={props.isDesktop}
        isTablet={props.isTablet}
        isMobile={props.isMobile}
      />
    );
  };

  const renderOpenWorldPvpInviteInfo = (activityInfo: EnumInfo) => {
    const details = props.invite.details as OpenWorldPvpDetails;
    return (
      <InviteInfo
        iconSrc={activityInfo.icon}
        activityName={activityInfo.text.toLowerCase()}
        activityPrimaryOption={details.type || "Any Type"}
        isDesktop={props.isDesktop}
        isTablet={props.isTablet}
        isMobile={props.isMobile}
      />
    );
  };

  const renderBasicInviteInfo = (activityInfo: EnumInfo) => {
    const details = props.invite.details;
    return (
      <InviteInfo
        iconSrc={activityInfo.icon}
        activityName={activityInfo.text.toLowerCase()}
        activityPrimaryOption={details.type || "Any Zone"}
        isDesktop={props.isDesktop}
        isTablet={props.isTablet}
        isMobile={props.isMobile}
      />
    );
  };

  const activity: Activity = props.invite.activity;
  if (activity === Activity.ARENA) {
    return renderArenaInviteInfo(getActivityEnumInfo(activity));
  } else if (activity === Activity.DUNGEON || activity === Activity.TRIAL) {
    return renderDungeonInviteInfo(getActivityEnumInfo(activity));
  } else if (
    activity === Activity.WORLD_BOSS ||
    activity === Activity.DOLMEN ||
    activity === Activity.DELVE
  ) {
    return renderZonedInviteInfo(getActivityEnumInfo(activity));
  } else if (
    activity === Activity.IMPERIAL_CITY ||
    activity === Activity.CYRO
  ) {
    return renderOpenWorldPvpInviteInfo(getActivityEnumInfo(activity));
  } else {
    return renderBasicInviteInfo(getActivityEnumInfo(activity));
  }
};

export default InviteInfoAdapter;
