import * as React from "react";
import { useEffect } from "react";
import { Character } from "@burketyler/domain";
import ContextWrapperProps from "../../../domain/contextWrapperProps.model";
import CharactersContextModel from "../../../domain/contexts/charactersContext.model";
import isDefined from "../../../functions/isDefined.function";

export const CharactersContext = React.createContext<CharactersContextModel>(
  undefined
);

interface Props extends ContextWrapperProps {
  localStorage: { chars: Character[]; selected: Character; expires?: Date };
}

const CharactersContextWrapper: React.FC<Props> = (
  props: Props
): JSX.Element => {
  const [expires, setExpires] = React.useState(
    props.localStorage && props.localStorage.expires
      ? props.localStorage.expires
      : (undefined as Date)
  );
  const [chars, setChars] = React.useState(
    props.localStorage ? props.localStorage.chars : (undefined as Character[])
  );
  const [selected, setSelected] = React.useState(
    props.localStorage ? props.localStorage.selected : (undefined as Character)
  );

  const clearContext = (): void => {
    setExpires(undefined);
    setChars(undefined);
    setSelected(undefined);
  };

  useEffect(() => {
    if (isDefined(chars) || isDefined(selected) || isDefined(expires)) {
      if (expires) {
        props.onChange({ chars, selected, expires });
      } else {
        props.onChange({ chars, selected });
      }
    }
  });

  return (
    <CharactersContext.Provider
      value={{
        setChars,
        chars,
        selected,
        setSelected,
        expires,
        setExpires,
        clearContext
      }}
    >
      {props.children}
    </CharactersContext.Provider>
  );
};

export default CharactersContextWrapper;
