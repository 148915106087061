import * as React from "react";
import LSAProps from "./localStorageAdapter.model";
import isDefined from "../../functions/isDefined.function";

interface Props {
  lsKey: string;
  component: React.FC<LSAProps>;
  onHydrate?: (item: any) => void;
  children?: any;
}

const LocalStorageAdapter: React.FC<Props> = (props: Props): JSX.Element => {
  const getLocalStorageItem = (key: string): any => {
    const now: Date = new Date();
    const lsString: string = window.localStorage.getItem(key);
    let item: any;
    if (lsString) {
      try {
        item = JSON.parse(lsString);
        if (isDefined(item.expires)) {
          if (now < new Date(item.expires)) {
            if (props.onHydrate) {
              props.onHydrate(item);
            }
            return item;
          } else {
            window.localStorage.removeItem(key);
            return undefined;
          }
        } else {
          if (props.onHydrate) {
            props.onHydrate(item);
          }
          return item;
        }
      } catch (e) {
        throw new Error(
          `Unable to parse local storage item for key ${key}. It doesn't appear to be valid JSON: ${e.message}.`
        );
      }
    }
  };

  const putLocalStorageItem = (lsKey: string, item: any): void => {
    try {
      if (isDefined(item)) {
        window.localStorage.setItem(
          lsKey,
          JSON.stringify(item, (key: string, value: any) => {
            return typeof value === "undefined" ? null : value;
          })
        );
      }
    } catch (e) {
      throw new Error(`Unable to save item to local storage: ${e.message}.`);
    }
  };

  return (
    <props.component
      localStorage={getLocalStorageItem(props.lsKey)}
      onChange={(item: any) => putLocalStorageItem(props.lsKey, item)}
    >
      {props.children}
    </props.component>
  );
};

export default LocalStorageAdapter;
