import * as React from "react";
import { SpanAnchorButtonContainer } from "./AnchorButton.styled";

interface Props {
  size?: string;
  onClick: () => void;
  children?: string;
}

const AnchorButton: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <SpanAnchorButtonContainer size={props.size} onClick={props.onClick}>
      {props.children}
    </SpanAnchorButtonContainer>
  );
};

export default AnchorButton;
