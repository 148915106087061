import GlobalContextModel from "../domain/contexts/globalContext.model";
import isDefined from "./isDefined.function";

function isLoggedIn(context: GlobalContextModel): boolean {
  return (
    isDefined(context) &&
    isDefined(context.user) &&
    isDefined(context.user.id) &&
    isDefined(context.bearerToken)
  );
}

export default isLoggedIn;
