import * as React from "react";
import GroupContextModel from "../../domain/contexts/groupContext.model";
import { useContext } from "react";
import { GroupContext } from "../contexts/group-context-wrapper/GroupContextWrapper";
import { useParams } from "react-router-dom";
import NotFoundPage from "../../pages/not-found/NotFoundPage";
import isInGroup from "../../functions/isInGroup.function";

interface Props {
  children: any;
}

const MustBeInGroupGuard: React.FC<Props> = (props: Props): JSX.Element => {
  const params: any = useParams();
  const groupCtx: GroupContextModel = useContext(GroupContext);

  const render = (): JSX.Element => {
    if (
      !isInGroup(groupCtx) ||
      (groupCtx.isInGroup && groupCtx.group.groupId !== params.groupId)
    ) {
      return <NotFoundPage />;
    } else {
      return props.children;
    }
  };

  return render();
};

export default MustBeInGroupGuard;
