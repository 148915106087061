import GlobalContextModel from "../domain/contexts/globalContext.model";
import GroupContextModel from "../domain/contexts/groupContext.model";
import LfgContextModel from "../domain/contexts/lfgContext.model";
import { NavLinkModel } from "../components/navbar/Navbar.domain";
import { User } from "@burketyler/domain";
import isLoggedIn from "./isLoggedIn.function";
import isInGroup from "./isInGroup.function";
import isInLfg from "./isInLfg.function";

export const genericNavLinks: NavLinkModel[] = [
  { label: "Support", icon: "help-circle", href: "/#/support" },
  { label: "Donate", icon: "card", href: "/#/donate" },
  { label: "|", icon: "|", href: "|" }
];

export function secureNavLinks(user: User): NavLinkModel[] {
  return [
    {
      label: user.gamerTag,
      icon: "person-circle",
      href: "/#/",
      dropDown: [
        {
          label: "Characters",
          icon: "people",
          href: "/#/account/characters"
        },
        {
          label: "Account",
          icon: "options",
          href: "/#/account"
        },
        {
          label: "Change password",
          icon: "lock-closed",
          href: "/#/account/password/change"
        },
        {
          label: "Change email",
          icon: "mail",
          href: "/#/account/email/change"
        },
        {
          label: "Log out",
          icon: "power",
          href: "/#/logout"
        }
      ]
    }
  ];
}

export const publicNavLinks: NavLinkModel[] = [
  { label: "Create account", icon: "add-circle", href: "/#/register" },
  { label: "Log In", icon: "person-circle", href: "/#/login" }
];

function createNavlinks(
  ctx: GlobalContextModel,
  grpCtx: GroupContextModel,
  lfgCtx: LfgContextModel
): NavLinkModel[] {
  let links: NavLinkModel[] = [];
  if (isInGroup(grpCtx)) {
    links.push({
      label: "Group",
      icon: "people",
      href: `/#/group/${grpCtx.group.groupId}`
    });
  } else if (isInLfg(lfgCtx)) {
    links.push({
      label: "Lfg",
      icon: "person",
      href: `/#/lfg/${lfgCtx.lfg.char.userId}`
    });
  } else {
    links.push({
      label: "Home",
      icon: "home",
      href: `/#/`
    });
  }
  links = links.concat(genericNavLinks);
  if (isLoggedIn(ctx)) {
    links = links.concat(secureNavLinks(ctx.user));
  } else {
    links = links.concat(publicNavLinks);
  }
  return links;
}

export default createNavlinks;
