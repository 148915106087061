import * as React from "react";

const scrollRefIntoView = (ref: React.MutableRefObject<HTMLDivElement>) => {
  ref.current.scrollIntoView({
    behavior: "smooth",
    block: "start"
  });
};

export default scrollRefIntoView;
