import * as React from "react";
import { Breadcrumb } from "./breadcrumbs.model";
import TextMontserratLight from "../text/TextMontserratLight";
import DivFlex from "../../styled/DivFlex";
import {
  AnchorBreadCrumb,
  DivBreadCrumbsContainer
} from "./Breadcrumbs.styled";
import DivMargin from "../../styled/DivMargin";
import TextMontserrat from "../text/TextMontserrat";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../functions/breakpoints.function";
import { Fonts } from "../../../theme";
import { Helmet } from "react-helmet";

interface Props {
  breadcrumbs: Breadcrumb[];
  currentPage: string;
}

const Breadcrumbs: React.FC<Props> = (props: Props): JSX.Element => {
  const isMobile: boolean = useMediaQuery(breakpoints.isMobile);

  const generateBreadCrumbStructuredData = (): string => {
    return JSON.stringify({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        props.breadcrumbs.map((bc, index) => {
          return {
            "@type": "ListItem",
            position: index + 1,
            name: bc.label,
            item: `${window.location.protocol}//${window.location.host}${bc.href}`
          };
        }),
        {
          "@type": "ListItem",
          position: props.breadcrumbs.length + 1,
          name: props.currentPage
        }
      ]
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{props.currentPage}</title>
        <script type="application/ld+json">
          {generateBreadCrumbStructuredData()}
        </script>
      </Helmet>
      <DivMargin bottom={30} top={10}>
        <DivFlex justify={"flex-start"} align={"center"}>
          {props.breadcrumbs.map((crumb, index) => {
            return (
              <DivBreadCrumbsContainer isMobile={isMobile} key={crumb.label}>
                <AnchorBreadCrumb isMobile={isMobile} href={crumb.href}>
                  {crumb.label}
                </AnchorBreadCrumb>
                <TextMontserratLight
                  size={isMobile ? Fonts.size.small : Fonts.size.regular}
                >
                  /
                </TextMontserratLight>
              </DivBreadCrumbsContainer>
            );
          })}
          <TextMontserrat
            size={isMobile ? Fonts.size.small : Fonts.size.regular}
          >
            {props.currentPage}
          </TextMontserrat>
        </DivFlex>
      </DivMargin>
    </React.Fragment>
  );
};

export default Breadcrumbs;
