import React from "react";
import { setConfiguration, Container, Col, Row } from "react-grid-system";
import Router from "./Router";
import GlobalContextWrapper from "./components/contexts/global-context-wrapper/GlobalContextWrapper";
import LocalStorageAdapter from "./components/local-storage-adapter/LocalStorageAdapter";
import {
  LS_KEY_ACCOUNT_CONTEXT,
  LS_KEY_CHARS_CONTEXT,
  LS_KEY_DATA_CONTEXT,
  LS_KEY_GLOBAL_CONTEXT,
  LS_KEY_GROUP_CONTEXT,
  LS_KEY_LFG_CONTEXT
} from "./constants";
import hydrateUserContext from "./functions/hydrateUserContext.function";
import AccountContextWrapper from "./components/contexts/account-context-wrapper/AccountContextWrapper";
import CharactersContextWrapper from "./components/contexts/characters-context-wrapper/CharactersContextWrapper";
import GroupContextWrapper from "./components/contexts/group-context-wrapper/GroupContextWrapper";
import LfgContextWrapper from "./components/contexts/lfg-context-wrapper/LfgContextWrapper";
import EnvironmentConfig from "./environmentConfig";
import { HttpClientComponent } from "@burketyler/utils";
import interceptHttpError from "./functions/interceptHttpError.function";
import * as Sentry from "@sentry/react";
import InternalErrorPage from "./pages/internal-error/InternalErrorPage";
import { createHashHistory } from "history";
import ActivityInitializer from "./components/activity-initializer/ActivityInitializer";
import DataContextWrapper from "./components/contexts/data-context-wrapper/DataContextWrapper";
import DataInitializer from "./components/data-initializer/DataInitializer";
import isLocalStorageEnabled from "./functions/isLocalStorageEnabled.function";
import TextMontserratSemiBold from "./components/toolkit/text/TextMontserratSemiBold";
import H1NoStyle from "./components/styled/H1NoStyle";
import { Fonts } from "./theme";
import { Palette } from "./palette";
import TextMontserrat from "./components/toolkit/text/TextMontserrat";
import DivMargin from "./components/styled/DivMargin";
import RecaptchaWrapper from "./components/recaptcha-wrapper/RecaptchaWrapper";

export const env: EnvironmentConfig = new EnvironmentConfig();
export const http: HttpClientComponent = new HttpClientComponent();

http.setErrorInterceptor(interceptHttpError);
setConfiguration({ gutterWidth: 0 });

if (env.stage !== "local") {
  Sentry.init({
    dsn: env.sentryDsn,
    release: env.build,
    environment: env.stage,
    debug: env.sentryDebugMode
  });
}

export const history = createHashHistory({ hashType: "slash" });

const App: React.FC = () => {
  const renderLsNotSupported = () => (
    <Container>
      <Row>
        <Col>
          <DivMargin top={50}>
            <DivMargin bottom={20}>
              <TextMontserratSemiBold
                size={Fonts.size.large}
                color={Palette.secondary}
              >
                <H1NoStyle>Browser not supported</H1NoStyle>
              </TextMontserratSemiBold>
            </DivMargin>
            <TextMontserrat el={"div"}>
              This browser doesn't support Local Storage. We use Local Storage
              to cache ESO LFG data so that pages load faster and we reduce the
              load on our servers. We do not use Local Storage to save
              sensitive/private information or record your activity in any way.
              <br />
              <br />
              If you've disabled "all website data" or "all cookies" from being
              saved in your browser settings, this will often include Local
              Storage.
              <br />
              <br />
              To use this site, please enabled access to Local Storage.
            </TextMontserrat>
          </DivMargin>
        </Col>
      </Row>
    </Container>
  );

  const renderApp = () => (
    <Sentry.ErrorBoundary fallback={InternalErrorPage}>
      <RecaptchaWrapper>
        <LocalStorageAdapter
          lsKey={LS_KEY_GLOBAL_CONTEXT}
          component={GlobalContextWrapper}
          onHydrate={hydrateUserContext}
        >
          <LocalStorageAdapter
            lsKey={LS_KEY_DATA_CONTEXT}
            component={DataContextWrapper}
          >
            <LocalStorageAdapter
              lsKey={LS_KEY_ACCOUNT_CONTEXT}
              component={AccountContextWrapper}
            >
              <LocalStorageAdapter
                lsKey={LS_KEY_CHARS_CONTEXT}
                component={CharactersContextWrapper}
              >
                <LocalStorageAdapter
                  lsKey={LS_KEY_GROUP_CONTEXT}
                  component={GroupContextWrapper}
                >
                  <LocalStorageAdapter
                    lsKey={LS_KEY_LFG_CONTEXT}
                    component={LfgContextWrapper}
                  >
                    <DataInitializer>
                      <ActivityInitializer>
                        <Router />
                      </ActivityInitializer>
                    </DataInitializer>
                  </LocalStorageAdapter>
                </LocalStorageAdapter>
              </LocalStorageAdapter>
            </LocalStorageAdapter>
          </LocalStorageAdapter>
        </LocalStorageAdapter>
      </RecaptchaWrapper>
    </Sentry.ErrorBoundary>
  );

  return isLocalStorageEnabled() ? renderApp() : renderLsNotSupported();
};

export default App;
