import * as React from "react";
import { DivButtonLoader } from "./Button.styled";

interface Props {}

const ButtonLoader: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <DivButtonLoader>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </DivButtonLoader>
  );
};

export default ButtonLoader;
