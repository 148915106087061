import GlobalContextModel from "../domain/contexts/globalContext.model";
import GroupContextModel from "../domain/contexts/groupContext.model";
import LfgContextModel from "../domain/contexts/lfgContext.model";
import { NavLinkModel } from "../components/navbar/Navbar.domain";
import isLoggedIn from "./isLoggedIn.function";
import isInGroup from "./isInGroup.function";
import isInLfg from "./isInLfg.function";

export const genericMenuLinks: NavLinkModel[] = [
  { label: "Support", icon: "help-circle", href: "/#/support" },
  { label: "Donate", icon: "card", href: "/#/donate" }
];

export const publicMenuLinks: NavLinkModel[] = [
  { label: "Create account", icon: "person-add", href: "/#/register" },
  { label: "Log In", icon: "person-circle", href: "/#/login" }
];

export function secureMenulinks(): NavLinkModel[] {
  return [
    {
      label: "Characters",
      icon: "people",
      href: "/#/account/characters"
    },
    {
      label: "Account",
      icon: "options",
      href: "/#/account"
    },
    {
      label: "Change password",
      icon: "lock-closed",
      href: "/#/account/password/change"
    },
    {
      label: "Change email",
      icon: "mail",
      href: "/#/account/email/change"
    },
    {
      label: "Log out",
      icon: "power",
      href: "/#/logout"
    }
  ];
}

function createMenulinks(
  ctx: GlobalContextModel,
  grpCtx: GroupContextModel,
  lfgCtx: LfgContextModel
): NavLinkModel[] {
  let links: NavLinkModel[] = [];
  if (isInGroup(grpCtx)) {
    links.push({
      label: "Group",
      icon: "people",
      href: `/#/group/${grpCtx.group.groupId}`
    });
  } else if (isInLfg(lfgCtx)) {
    links.push({
      label: "Lfg",
      icon: "person",
      href: `/#/lfg/${lfgCtx.lfg.char.userId}`
    });
  } else {
    links.push({
      label: "Home",
      icon: "home",
      href: `/#/`
    });
  }
  links = links.concat(genericMenuLinks);
  if (isLoggedIn(ctx)) {
    links = links.concat(secureMenulinks());
  } else {
    links = links.concat(publicMenuLinks);
  }
  return links;
}

export default createMenulinks;
