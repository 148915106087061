import * as React from "react";
import LfgContextModel from "../../domain/contexts/lfgContext.model";
import { useContext } from "react";
import { LfgContext } from "../contexts/lfg-context-wrapper/LfgContextWrapper";
import GroupContextModel from "../../domain/contexts/groupContext.model";
import { GroupContext } from "../contexts/group-context-wrapper/GroupContextWrapper";
import { GlobalContext } from "../contexts/global-context-wrapper/GlobalContextWrapper";
import isLoggedIn from "../../functions/isLoggedIn.function";
import onFirstRender from "../../functions/onFirstRender.function";
import contextFn from "../../functions/contexts/context.function";
import setNotification from "../../functions/setNotification.function";
import { genericError } from "../../constants";
import isInGroup from "../../functions/isInGroup.function";
import isEventSourceConnected from "../../functions/isEventSoureConnected.function";
import groupStreamFn from "../../functions/contexts/groupStream.function";
import isInLfg from "../../functions/isInLfg.function";
import lfgStreamFn from "../../functions/contexts/lfgStream.function";
import { history } from "../../App";
import isActivated from "../../functions/isActivated.function";
import GlobalContextModel from "../../domain/contexts/globalContext.model";

interface Props {
  children: any;
}

const ActivityInitializer: React.FC<Props> = (props: Props): JSX.Element => {
  const lfgCtx: LfgContextModel = useContext(LfgContext);
  const groupCtx: GroupContextModel = useContext(GroupContext);
  const globalCtx: GlobalContextModel = useContext(GlobalContext);

  const shouldCheckForActivity = (): boolean => {
    return (
      isLoggedIn(globalCtx) &&
      isActivated(globalCtx) &&
      ((!lfgCtx.isInLfg && !groupCtx.isInGroup) ||
        (lfgCtx.isInLfg && (!lfgCtx.lfg || !lfgCtx.lfg.char)) ||
        (groupCtx.isInGroup && (!groupCtx.group || !groupCtx.group.groupId)))
    );
  };

  onFirstRender(() => {
    if (shouldCheckForActivity()) {
      contextFn
        .isInActivity(globalCtx.user.id, globalCtx, lfgCtx, groupCtx, history)
        .catch(() => {
          setNotification(globalCtx, "error", genericError);
        });
    } else {
      if (
        isLoggedIn(globalCtx) &&
        isInGroup(groupCtx) &&
        !isEventSourceConnected(groupCtx.eventSource)
      ) {
        groupStreamFn.connectToGroup(
          groupCtx.group.groupId,
          globalCtx.user.id,
          groupCtx.group.lastEvent,
          globalCtx,
          groupCtx,
          history
        );
      }
      if (
        isLoggedIn(globalCtx) &&
        isInLfg(lfgCtx) &&
        !isEventSourceConnected(lfgCtx.eventSource)
      ) {
        lfgStreamFn.connectToLfg(
          globalCtx.user.id,
          lfgCtx.lfg.lastEvent,
          globalCtx,
          lfgCtx,
          groupCtx,
          history
        );
      }
    }
  });

  return props.children;
};

export default ActivityInitializer;
