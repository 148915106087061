import styled from "styled-components/macro";
import { Palette } from "../../../palette";

export const DivCardContainer = styled.div`
  backface-visibility: hidden;
  background-color: ${Palette.bgPrimary};
  padding: 20px;
`;

export const DivCardPageContainer = styled.div<{ height: number }>`
  min-height: ${props => props.height}px;
  backface-visibility: hidden;
  background-color: ${Palette.bgPrimary};
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const DivCardSeparator = styled.div`
  width: 100px;
  height: 2px;
  background-color: ${Palette.secondary};
`;

export const DivCardHeadingRightContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${props => (props.isMobile ? 5 : 25)}px;
`;
