import styled from "styled-components/macro";
import { Palette } from "../../palette";
import { Fonts, Transitions } from "../../theme";

const Anchor = styled.a<{ size?: string }>`
  color: ${Palette.link};
  font-family: ${Fonts.face.montserrat};
  font-weight: ${Fonts.weight.semiBold};
  font-size: ${props => (props.size ? props.size : Fonts.size.regular)};
  text-decoration: none;
  transition: ${Transitions.navLink};
  &:hover {
    color: ${Palette.linkHover};
  }
`;

export default Anchor;
