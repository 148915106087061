import * as React from "react";
import { DivIoniconContainer } from "./Ionicon.styled";

interface Props {
  name: string;
  size?: string;
  color?: string;
  spinner?: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
}

const Ionicon: React.FC<Props> = (props: Props) => {
  return (
    <DivIoniconContainer {...props}>
      <ion-icon
        onMouseOver={props.onMouseOver}
        onMouseLeave={props.onMouseLeave}
        onClick={props.onClick}
        name={props.name}
      />
    </DivIoniconContainer>
  );
};

export default Ionicon;
