import {
  ConnectionStatus,
  ContentType,
  EventData,
  GroupEventType,
  GroupMember,
  MemberRoleData,
  NotFoundError
} from "@burketyler/domain";
import GlobalContextModel from "../../domain/contexts/globalContext.model";
import SpaGroup from "../../domain/spaGroup.model";
import { arraySafeRemove } from "@burketyler/utils";
import setGlobalUserPrompt from "../setGlobalUserPrompt.function";
import * as H from "history";
import GroupContextModel from "../../domain/contexts/groupContext.model";
import EventSource from "eventsource";
import safeCloseEventSource from "../safeCloseEventSource.function";
import { env, http } from "../../App";
import setNotification from "../setNotification.function";
import { SetState } from "../../domain/setState.model";
import SpaGroupMember from "../../domain/spaGroupMember.model";
import mapSpaGroupMember from "../../mapping/entities/mapSpaGroupMember.function";
import SpaGroupEvent from "../../domain/spaGroupEvent.model";

function connectToGroup(
  groupId: string,
  userId: string,
  lastEvent: number,
  globalCtx: GlobalContextModel,
  groupCtx: GroupContextModel,
  history: H.History,
  token?: string
): void {
  console.log("Connecting to group");
  const eventSource = new EventSource(
    `${env.seGroupHost}/group/${groupId}/user/${userId}/stream`,
    {
      headers: {
        Authorization: `Bearer ${token || globalCtx.bearerToken}`,
        "Last-Event-ID": lastEvent.toString()
      }
    }
  );
  if (groupCtx.errorTimeout) {
    clearTimeout(groupCtx.errorTimeout);
  }
  addEventListeners(userId, eventSource, groupCtx, globalCtx, history);
  groupCtx.setEventSource(eventSource);
}

function addEventListeners(
  loggedInUserId: string,
  eventSource: EventSource,
  groupCtx: GroupContextModel,
  globalCtx: GlobalContextModel,
  history: H.History
): void {
  eventSource.onerror = (event: any): void => {
    handleError.bind(eventSource)(
      groupCtx.clearContext,
      globalCtx,
      groupCtx.setErrorTimeout,
      event
    );
  };
  eventSource.addEventListener(
    GroupEventType.MEMBER_ADD.toString(),
    (event: any): void => {
      handleMemberJoin(groupCtx.setGroup, groupCtx.setEvents, event);
    }
  );
  eventSource.addEventListener(
    `${GroupEventType.MEMBER_LEAVE}`,
    (event: any): void => {
      handleMemberLeave(
        groupCtx.clearContext,
        groupCtx.setGroup,
        groupCtx.setEvents,
        history,
        globalCtx,
        event
      );
    }
  );
  eventSource.addEventListener(
    `${GroupEventType.MEMBER_STATUS}`,
    (event: any): void => {
      handleChangeMemberStatus(groupCtx.setGroup, groupCtx.setEvents, event);
    }
  );
  eventSource.addEventListener(
    `${GroupEventType.UPDATE}`,
    (event: any): void => {
      handleGroupUpdate(groupCtx.setGroup, groupCtx.setEvents, event);
    }
  );
  eventSource.addEventListener(
    `${GroupEventType.MEMBER_KICK}`,
    (event: any): void => {
      handleMemberKicked(
        loggedInUserId,
        groupCtx.clearContext,
        groupCtx.setGroup,
        groupCtx.setEvents,
        history,
        globalCtx,
        event
      );
    }
  );
  eventSource.addEventListener(
    `${GroupEventType.MEMBER_ROLE}`,
    (event: any): void => {
      handleChangeMemberRole(groupCtx.setGroup, groupCtx.setEvents, event);
    }
  );
  eventSource.addEventListener(`${GroupEventType.END}`, (event: any): void => {
    handleDisband(
      groupCtx.clearContext,
      groupCtx.setEvents,
      history,
      globalCtx,
      event
    );
  });
  eventSource.addEventListener(
    `${GroupEventType.MESSAGE}`,
    (event: any): void => {
      handleChatMessage(groupCtx.setGroup, groupCtx.setEvents, event);
    }
  );
  eventSource.addEventListener(
    `${GroupEventType.LEADER_CHANGE}`,
    (event: any): void => {
      handleChangeLeader(groupCtx.setGroup, groupCtx.setEvents, event);
    }
  );
  eventSource.addEventListener(
    `${GroupEventType.STATUS}`,
    (event: any): void => {
      handleChangeGroupStatus(groupCtx.setGroup, groupCtx.setEvents, event);
    }
  );
}

function removeMember(
  userId: string,
  setGroup: SetState<SpaGroup>,
  lastEventId: number
) {
  setGroup(prevState => {
    prevState.members.find(gm => gm.userId === userId).exited = true;
    return {
      ...prevState,
      members: Array.from(prevState.members),
      lastEvent: lastEventId
    };
  });
}

function getMember(members: SpaGroupMember[], userId: string): SpaGroupMember {
  if (members && members.length > 0) {
    return members.find(gm => gm.userId === userId);
  }
}

function addEvent(
  setEvents: SetState<SpaGroupEvent[]>,
  event: SpaGroupEvent
): void {
  setEvents(prevState => {
    prevState.push(event);
    return Array.from(prevState);
  });
}

function handleGroupUpdate(
  setGroup: SetState<SpaGroup>,
  setEvents: SetState<SpaGroupEvent[]>,
  event: any
): void {
  console.log("UpdateGroup");
  const updateEvent: EventData = JSON.parse(event.data);
  setGroup(prevState => {
    return {
      ...prevState,
      ...updateEvent.data,
      lastEvent: Number(event.lastEventId)
    };
  });
  addEvent(setEvents, { type: GroupEventType.UPDATE, event: updateEvent });
}

function handleMemberJoin(
  setGroup: SetState<SpaGroup>,
  setEvents: SetState<SpaGroupEvent[]>,
  event: any
): void {
  console.log("MemberJoin");
  const addMemberEvent: EventData = JSON.parse(event.data);
  const member: GroupMember = addMemberEvent.data.member;
  const requiredMemberIndex: number = addMemberEvent.data.requiredMemberIndex;
  setGroup(prevState => {
    const existingMember = getMember(prevState.members, member.userId);
    if (existingMember) {
      arraySafeRemove(existingMember, prevState.members);
    }
    prevState.requiredMembers.splice(requiredMemberIndex, 1);
    prevState.members.push(mapSpaGroupMember(member));
    return {
      ...prevState,
      members: Array.from(prevState.members),
      lastEvent: Number(event.lastEventId)
    };
  });
  addEvent(setEvents, {
    type: GroupEventType.MEMBER_ADD,
    event: addMemberEvent
  });
}

function handleMemberKicked(
  loggedInUserId: string,
  clearContext: SetState<void>,
  setGroup: SetState<SpaGroup>,
  setEvents: SetState<SpaGroupEvent[]>,
  history: H.History,
  globalCtx: GlobalContextModel,
  event: any
): void {
  console.log("MemberKicked");
  const memberKickEvent: EventData = JSON.parse(event.data);
  if (memberKickEvent.data === loggedInUserId) {
    setGlobalUserPrompt(globalCtx, {
      visible: true,
      title: "User kicked",
      text: "The leader has kicked you from the group.",
      buttons: [{ variant: "primary", text: "Leave" }],
      onButtonClicked: () => {
        clearContext();
        history.push("/");
        setGlobalUserPrompt(globalCtx, undefined);
      }
    });
  } else {
    removeMember(memberKickEvent.data, setGroup, Number(event.lastEventId));
    addEvent(setEvents, {
      type: GroupEventType.MEMBER_KICK,
      event: memberKickEvent
    });
  }
}

function handleMemberLeave(
  clearContext: SetState<void>,
  setGroup: SetState<SpaGroup>,
  setEvents: SetState<SpaGroupEvent[]>,
  history: H.History,
  globalCtx: GlobalContextModel,
  event: any
): void {
  console.log("MemberLeave");
  const memberLeaveEvent: EventData = JSON.parse(event.data);
  if (memberLeaveEvent.senderId === globalCtx.user.id) {
    clearContext();
    history.push("/");
  } else {
    removeMember(
      memberLeaveEvent.senderId,
      setGroup,
      Number(event.lastEventId)
    );
    addEvent(setEvents, {
      type: GroupEventType.MEMBER_LEAVE,
      event: memberLeaveEvent
    });
  }
}

function handleChangeGroupStatus(
  setGroup: SetState<SpaGroup>,
  setEvents: SetState<SpaGroupEvent[]>,
  event: any
): void {
  console.log("ChangeGroupStatus");
  const groupStatusEvent: EventData = JSON.parse(event.data);
  setGroup(prevState => {
    return {
      ...prevState,
      status: groupStatusEvent.data.status,
      lastEvent: Number(event.lastEventId)
    };
  });
  addEvent(setEvents, { type: GroupEventType.STATUS, event: groupStatusEvent });
}

function handleChangeMemberStatus(
  setGroup: SetState<SpaGroup>,
  setEvents: SetState<SpaGroupEvent[]>,
  event: any
): void {
  console.log("ChangeMemberStatus");
  const memberStatusEvent: EventData = JSON.parse(event.data);
  setGroup(prevState => {
    getMember(prevState.members, memberStatusEvent.senderId).status =
      memberStatusEvent.data;
    return {
      ...prevState,
      members: Array.from(prevState.members),
      lastEvent: Number(event.lastEventId)
    };
  });
  addEvent(setEvents, {
    type: GroupEventType.MEMBER_STATUS,
    event: memberStatusEvent
  });
}

function handleChangeMemberRole(
  setGroup: SetState<SpaGroup>,
  setEvents: SetState<SpaGroupEvent[]>,
  event: any
): void {
  console.log("ChangeRole");
  const memberRoleEvent: EventData = JSON.parse(event.data);
  const data: MemberRoleData = memberRoleEvent.data;
  setGroup(prevState => {
    getMember(prevState.members, data.userId).role = data.role;
    return {
      ...prevState,
      members: Array.from(prevState.members),
      lastEvent: Number(event.lastEventId)
    };
  });
  addEvent(setEvents, {
    type: GroupEventType.MEMBER_ROLE,
    event: memberRoleEvent
  });
}

function handleChangeLeader(
  setGroup: SetState<SpaGroup>,
  setEvents: SetState<SpaGroupEvent[]>,
  event: any
): void {
  console.log("ChangeLeader");
  const changeLeaderEvent: EventData = JSON.parse(event.data);
  setGroup(prevState => {
    const member: GroupMember = getMember(
      prevState.members,
      changeLeaderEvent.data
    );
    return {
      ...prevState,
      leaderId: member.userId,
      leaderName: member.gamerTag,
      lastEvent: Number(event.lastEventId)
    };
  });
  addEvent(setEvents, {
    type: GroupEventType.LEADER_CHANGE,
    event: changeLeaderEvent
  });
}

function handleDisband(
  clearContext: SetState<void>,
  setEvents: SetState<SpaGroupEvent[]>,
  history: H.History,
  globalCtx: GlobalContextModel,
  event: any
): void {
  console.log("Disband");
  const disbandGroupEvent: EventData = JSON.parse(event.data);
  addEvent(setEvents, { type: GroupEventType.END, event: disbandGroupEvent });
  if (disbandGroupEvent.senderId !== globalCtx.user.id) {
    setGlobalUserPrompt(globalCtx, {
      visible: true,
      title: "Group ended",
      text: "The group has been disbanded by the leader.",
      buttons: [{ variant: "primary", text: "Leave" }],
      onButtonClicked: () => {
        clearContext();
        if (history.location.pathname.split("/")[1] === "group") {
          history.push("/");
        }
        setGlobalUserPrompt(globalCtx, undefined);
      }
    });
  }
  clearContext();
  history.push("/");
}

function handleChatMessage(
  setGroup: SetState<SpaGroup>,
  setEvents: SetState<SpaGroupEvent[]>,
  event: any
): void {
  console.log("ChatMessage");
  const chatMessageEvent: EventData = JSON.parse(event.data);
  setGroup(prevState => {
    return { ...prevState, lastEvent: event.lastEventId };
  });
  addEvent(setEvents, {
    type: GroupEventType.MESSAGE,
    event: chatMessageEvent
  });
}

function handleError(
  this: EventSource,
  clearContext: SetState<void>,
  globalCtx: GlobalContextModel,
  setErrorTimeout: SetState<number>,
  event: any
): void {
  console.log("Error", event);
  const typedEvent: { status: number; message: string } = event;
  safeCloseEventSource(this);
  if (typedEvent.message === undefined) {
    // The source closed the connection
    console.log("Server disconnected stream");
  } else if (typedEvent.status === 404) {
    clearContext();
  } else if (typedEvent.message === "network error") {
    setErrorTimeout(
      setTimeout(() => {
        setNotification(
          globalCtx,
          "warning",
          "You aren't connected to the LFG stream. Please refresh the page to reconnect."
        );
      }, 5000)
    );
  } else {
    setNotification(
      globalCtx,
      "warning",
      "You aren't connected to the group stream. Please refresh the page try to reconnect."
    );
  }
}

async function changeConnectionStatus(
  status: ConnectionStatus,
  groupId: string,
  userId: string
): Promise<void> {
  await http
    .fetch(
      `${env.seGroupHost}/group/${groupId}/user/${userId}/status`,
      {
        method: "PUT",
        body: JSON.stringify(status)
      },
      ContentType.JSON
    )
    .catch(err => {
      if (err instanceof NotFoundError) {
      }
    });
}

const groupStreamFn = {
  connectToGroup,
  addEventListeners,
  changeConnectionStatus
};

export default groupStreamFn;
