import * as React from "react";
import { createPortal } from "react-dom";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const Portal: React.FC<Props> = (props: Props): JSX.Element => {
  const root = document.getElementById("root");

  return createPortal(props.children, root);
};

export default Portal;
