import * as React from "react";
import MainLayout from "../../layouts/MainLayout";
import CardPage from "../../components/toolkit/card/CardPage";
import Portal from "../../components/toolkit/portal/Portal";
import FullPageLoadingIndicator from "../../components/toolkit/full-page-loading-indicator/FullPageLoadingIndicator";

interface Props {}

const ContentLoadingPage: React.FC<Props> = (props: Props): JSX.Element => {
  return (
    <MainLayout>
      <CardPage breadcrumbs={[]} heading={""}>
        <Portal>
          <FullPageLoadingIndicator isVisible={true} />
        </Portal>
      </CardPage>
    </MainLayout>
  );
};

export default ContentLoadingPage;
