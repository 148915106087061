import * as React from "react";
import { createRef, useEffect } from "react";
import {
  DivNavbarDropdownContainer,
  DivNavbarDropdownLink
} from "./Navbar.styled";
import { Col, Container, Row } from "react-grid-system";
import Ionicon from "../toolkit/ionicon/Ionicon";
import { NavLinkModel } from "./Navbar.domain";
import { navbarHeight } from "../../theme";
import AnchorNoStyle from "../styled/AnchorNoStyle";
import TextMontserrat from "../toolkit/text/TextMontserrat";

interface Props {
  isVisible: boolean;
  parent: React.RefObject<HTMLDivElement>;
  navLinks: NavLinkModel[];
  setActiveDropdown: (index: number) => void;
}

const NavbarDropdown: React.FC<Props> = (props: Props): JSX.Element => {
  const windowWidth: number = Math.trunc(window.innerWidth);
  const [parentRect, setParentRect] = React.useState({} as DOMRect);
  const [elementRef] = React.useState(createRef<HTMLDivElement>());

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        elementRef.current &&
        !elementRef.current.contains(event.target) &&
        !props.parent.current.contains(event.target)
      ) {
        props.setActiveDropdown(undefined);
      }
    };
    setParentRect(props.parent.current.getBoundingClientRect());
    if (props.isVisible) {
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
  }, [elementRef, props]);

  return (
    <DivNavbarDropdownContainer
      right={windowWidth - parentRect.right - 10}
      width={parentRect.width ? parentRect.width + 20 : 0}
      top={navbarHeight}
      isVisible={props.isVisible}
      ref={elementRef}
    >
      <Container fluid>
        {props.navLinks.map((link: NavLinkModel, index: number) => (
          <Row key={`${link.label}-${index}`}>
            <Col>
              <AnchorNoStyle href={link.href}>
                <DivNavbarDropdownLink>
                  <Ionicon name={link.icon} size={"24px"} />
                  <TextMontserrat>{link.label}</TextMontserrat>
                </DivNavbarDropdownLink>
              </AnchorNoStyle>
            </Col>
          </Row>
        ))}
      </Container>
    </DivNavbarDropdownContainer>
  );
};

export default NavbarDropdown;
