import parseBoolean from "./functions/parseBoolean.function";

class EnvironmentConfig {
  public build: string;
  public stage: string;
  public lambdaUserHost: string;
  public lambdaAccountHost: string;
  public lambdaCharsHost: string;
  public lambdaDataHost: string;
  public lambdaSupportHost: string;
  public lambdaDonateHost: string;
  public seGroupHost: string;
  public sentryDsn: string;
  public sentryDebugMode: boolean;
  public contentHost: string;
  public otpThrottleMs: number;
  public captchaEnabled: boolean;
  public captchaKey: string;
  public payPalMe: string;
  public stripePublicKey: string;
  public idleTimeMs: number;

  constructor() {
    this.build = process.env.REACT_APP_BUILD;
    this.stage = process.env.REACT_APP_STAGE;
    this.lambdaUserHost = process.env.REACT_APP_LAMBDA_USER_HOSTNAME;
    this.lambdaAccountHost = process.env.REACT_APP_LAMBDA_ACCOUNT_HOSTNAME;
    this.lambdaCharsHost = process.env.REACT_APP_LAMBDA_CHAR_HOSTNAME;
    this.lambdaDataHost = process.env.REACT_APP_LAMBDA_DATA_HOSTNAME;
    this.lambdaSupportHost = process.env.REACT_APP_LAMBDA_SUPPORT_HOSTNAME;
    this.lambdaDonateHost = process.env.REACT_APP_LAMBDA_DONATE_HOSTNAME;
    this.seGroupHost = process.env.REACT_APP_SE_GROUP_HOSTNAME;
    this.sentryDsn = process.env.REACT_APP_SENTRY_DSN;
    this.sentryDebugMode = parseBoolean(process.env.REACT_APP_SENTRY_DEBUG);
    this.contentHost = process.env.REACT_APP_CONTENT_HOSTNAME;
    this.otpThrottleMs = Number(process.env.REACT_APP_OTP_THROTTLE_MS);
    this.captchaEnabled = parseBoolean(process.env.REACT_APP_CAPTCHA_ENABLED);
    this.captchaKey = process.env.REACT_APP_CAPTCHA_PUBLIC_KEY;
    this.payPalMe = process.env.REACT_APP_PAYPAL_ME;
    this.stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
    this.idleTimeMs = Number(process.env.REACT_APP_IDLE_TIME_MS);
    this.validateEnvVars();
  }

  private validateEnvVars(): void {
    Object.entries(this).forEach(entry => {
      if (entry[1] === undefined) {
        throw new Error(`The environment variable ${entry[0]} is undefined.`);
      }
    });
  }
}

export default EnvironmentConfig;
