import styled from "styled-components/macro";
import { navbarHeight, Spacing, Transitions, zIndex } from "../../theme";
import { Palette } from "../../palette";
import { Container } from "react-grid-system";

export const DivNavbarContainer = styled.div<{ isMenuExtended?: boolean }>``;

export const DivNavbarLogo = styled.div<{ isMobile: boolean }>`
  height: ${navbarHeight}px;
  padding-left: ${props => (props.isMobile ? Spacing.four : Spacing.six)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    margin-right: ${props => (props.isMobile ? Spacing.three : Spacing.three)};
  }
`;

export const DivNavbarNavLinks = styled.div`
  height: ${navbarHeight}px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${Spacing.two} ${Spacing.six} 0 0;
`;

export const DivNavbarMenuIcon = styled.div<{ isMobile: boolean }>`
  height: ${navbarHeight}px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${props => (props.isMobile ? Spacing.two : Spacing.six)};
  cursor: pointer;
  user-select: none;
`;

export const DivNavbarLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${Spacing.one};
  ion-icon {
    margin-right: ${Spacing.three};
  }
`;

export const LinkNavbarDropdown = styled(DivNavbarLink)`
  justify-content: flex-start;
`;

export const SpanNavbarSpacer = styled.span`
  height: 15px;
  width: 1px;
  padding: 1px;
  margin-right: ${Spacing.four};
  background-color: #979797;
`;

export const DivNavbarHighlight = styled.div`
  display: block;
  background-color: ${Palette.alpha};
  height: 3px;
  transition: ${Transitions.navLink};
`;

export const ContainerNavbarLink = styled(Container)`
  user-select: none;
  cursor: pointer;
  margin-right: ${Spacing.four} !important;
  &:last-of-type {
    margin-right: 0 !important;
  }
  &:hover ${DivNavbarHighlight} {
    background-color: ${Palette.secondary};
  }
`;

export const ContainerNavbarUserLink = styled(ContainerNavbarLink)`
  &:hover {
    span:last-of-type {
      ion-icon {
        transition: ${Transitions.navLink};
        color: ${Palette.secondary};
      }
    }
  }
`;

export const DivNavbarDropdownContainer = styled.div<{
  right: number;
  top: number;
  width: number;
  isVisible: boolean;
}>`
  z-index: ${zIndex.control};
  position: absolute;
  ${props => (props.isVisible ? undefined : "display: none")};
  top: ${props => props.top}px;
  right: ${props => props.right}px;
  width: ${props => props.width}px;
  min-width: 250px;
  background-color: ${Palette.bgSecondary};
  padding: ${Spacing.four};
  div {
    div:last-of-type {
      div {
        div {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const DivNavbarDropdownLink = styled.div`
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  padding: ${Spacing.two} 0;
  margin-bottom: ${Spacing.two};
  border-left: 3px solid ${Palette.alpha};
  &:hover {
    transition: ${Transitions.navLink};
    border-left: 3px solid ${Palette.secondary};
  }
  ion-icon {
    padding: 0 ${Spacing.three} 0 ${Spacing.two};
  }
`;
