import { RadioModel } from "../../../components/toolkit/radio/radio.model";
import { Server } from "@burketyler/domain";

const serverRadioArray: RadioModel[] = [
  {
    label: "North America",
    value: Server.NA
  },
  {
    label: "Europe",
    value: Server.EUR
  }
];

export default serverRadioArray;
