import * as React from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import NotFoundPage from "../../pages/not-found/NotFoundPage";
import LfgContextModel from "../../domain/contexts/lfgContext.model";
import { LfgContext } from "../contexts/lfg-context-wrapper/LfgContextWrapper";
import isInLfg from "../../functions/isInLfg.function";

interface Props {
  children: any;
}

const MustBeInLfgGuard: React.FC<Props> = (props: Props): JSX.Element => {
  const params: any = useParams();
  const lfgCtx: LfgContextModel = useContext(LfgContext);

  const render = () => {
    if (
      !isInLfg(lfgCtx) ||
      (lfgCtx.isInLfg && lfgCtx.lfg.char.userId !== params.userId)
    ) {
      return <NotFoundPage />;
    } else {
      return props.children;
    }
  };

  return render();
};

export default MustBeInLfgGuard;
