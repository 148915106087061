import { env, http } from "../../App";
import { ZoneData } from "@burketyler/domain";
import GlobalContextModel from "../../domain/contexts/globalContext.model";
import DataContextModel from "../../domain/contexts/dataContext.model";

function getZoneData(
  globalCtx: GlobalContextModel,
  dataCtx: DataContextModel
): Promise<void> {
  return http
    .fetchJson<ZoneData[]>(`${env.lambdaDataHost}/data/zone`, {
      method: "GET"
    })
    .then(zoneData => {
      dataCtx.setZoneData(zoneData);
    });
}

const dataFn = {
  getZoneData
};

export default dataFn;
