import { LanguagePreference } from "@burketyler/domain";
import EnumInfo from "../../domain/enumInfo.model";

function getGroupLangPrefEnumInfo(langPref: LanguagePreference): EnumInfo {
  switch (langPref) {
    case LanguagePreference.ONLY_SAME:
      return {
        text: "I don’t care what language my group members speak."
      };
    case LanguagePreference.ANY_LANGUAGE:
      return {
        text: "Only match me with members who speak the same language as me."
      };
  }
}

export default getGroupLangPrefEnumInfo;
